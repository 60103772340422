import React, { useState } from "react";
import ReactGA from "react-ga4";
import { updateArtistProfile2Preferences } from "../rtsvrf-actions/Actions";
import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";
import { isArrayEqual } from "../rtsvrf-utils/Helpers";

function EditArtistProfileV2({
  user,
  userIpData,
  artist,
  artistProfile2Preferences,
  reloadData,
}) {
  const [loading, setLoading] = useState();
  const [settings, setSettings] = useState(artistProfile2Preferences);

  const handleForm = (name, value) => {
    if (name === "link_icons_list") {
      let list = settings["link_icons_list"] ?? [];
      if (list?.includes(value)) {
        list = list.filter((e) => e !== value);
      } else {
        list.push(value);
      }
      setSettings({ ...settings, [name]: list });
    } else if (
      [
        "merch_offer",
        "tickets_offer",
        "social_stream_embed1",
        "social_stream_embed2",
      ].includes(name)
    ) {
      setSettings({
        ...settings,
        [name]: settings[name] === value ? null : value,
      });
    } else {
      setSettings({ ...settings, [name]: value });
    }
    console.log(name, value, settings);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Save Artist Settings",
      action: "click",
      label: "Save Artist Settings",
    });

    let settingsDifference = Object.keys(settings).filter(
      (k) => settings[k] !== artistProfile2Preferences[k]
    );

    const settingsDetails =
      settingsDifference?.map(
        (field) =>
          `${field} changed from ${
            artistProfile2Preferences[field] || null
          } to ${settings[field] || null}`
      ) || [];

    const settingsResult = await updateArtistProfile2Preferences({
      ...settings,
    });

    setLoading(false);
    if (settingsResult?.error) {
      toast.error("Failed to update settings! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess(
        `${artist?.artist_name || "Artist"} Profile V2 Updated`,
        {
          ...userIpData,
          email: user.email,
          timestamp: new Date(),
          details: `${artist?.artist_name || "Artist"}:${
            artist?.rtsvrf_artist_id
          } ProfileV2:  ${[...settingsDetails].join(",")}, ${
            !isArrayEqual(
              settings.link_icons_list,
              artistProfile2Preferences.link_icons_list
            )
              ? `custom_artist_list changed from ${settings.link_icons_list} to ${artistProfile2Preferences.link_icons_list}`
              : ""
          }`,
          type: artist?.rtsvrf_artist_id,
        }
      );
      // reloadData();
    }
  };

  return (
    <>
      {settings && artist ? (
        <div className="my-10 w-full ml-5 lg:ml-0">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            Focus Artist - {artist?.artist_name} - {artist?.rtsvrf_artist_id}
          </h3>
          <div className="w-full my-2 flex flex-col justify-around bg-gray-200 shadow-xl rounded-lg p-4">
            <form className="w-full my-2 flex items-baseline flex-col justify-start">
              <div className="pr-4 w-full flex mt-4 md:flex-col">
                <h3 className="text-gray-800 w-52 text-xs font-bold mb-2">
                  Pick Main Artist Image:
                </h3>
                <div className="ml-2 flex items-center">
                  <input
                    id="artist_image"
                    type="checkbox"
                    checked={settings?.artist_image}
                    disabled={true}
                    onClick={() =>
                      handleForm("artist_image", !settings?.artist_image)
                    }
                    class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="artist_image"
                    className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                  >
                    Spotify/Default
                  </label>
                </div>
              </div>

              <div className="pr-4 w-full flex mt-4 md:flex-col">
                <h3 className="text-gray-800 w-52 whitespace-nowrap  text-xs font-bold mb-2">
                  Pick 1-5 Hero Link Icons:
                </h3>
                <div className="flex flex-col items-start">
                  <div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_website_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_website_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_website_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_website_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_website_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        WWW
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_spotfy_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_spotfy_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_spotfy_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_spotfy_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_spotfy_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Spotify
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_aplmus_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_aplmus_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_aplmus_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_aplmus_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_aplmus_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Apple Music
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_deezer_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_deezer_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_deezer_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_deezer_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_deezer_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Deezer
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_amazon_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_amazon_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_amazon_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_amazon_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_amazon_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Amazon Music
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_youtub_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_youtub_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_youtub_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_youtub_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_youtub_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Youtube
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_tiktok_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_tiktok_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_tiktok_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_tiktok_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_tiktok_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Tiktok
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_twitch_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_twitch_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_twitch_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_twitch_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_twitch_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Twitch
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_vimeo_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_vimeo_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_vimeo_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_vimeo_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_vimeo_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Vimeo
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_tidal_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_tidal_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_tidal_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_tidal_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_tidal_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Tidal
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_sound_cloud_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_sound_cloud_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_sound_cloud_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_sound_cloud_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_sound_cloud_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Soundcloud
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_audius_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_audius_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_audius_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_audius_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_audius_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Audius
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_sound_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_sound_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_sound_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_sound_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_sound_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Sound.xyz
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_facebk_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_facebk_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_facebk_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_facebk_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_facebk_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Facebook
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_twitter_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_twitter_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_twitter_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_twitter_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_twitter_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        X (Twitter)
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_threads_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_threads_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_threads_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_threads_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_threads_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Threads
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_snapchat_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_snapchat_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_snapchat_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_snapchat_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_snapchat_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Snapchat
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_mastodon_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_mastodon_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_mastodon_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_mastodon_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_mastodon_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Mastodon
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_bluesky_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_bluesky_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_bluesky_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_bluesky_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_bluesky_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        BlueSky
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_discord_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_discord_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_discord_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_discord_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_discord_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Discord
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_signal_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_signal_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_signal_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_signal_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_signal_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Signal
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_whatsapp_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_whatsapp_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_whatsapp_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_whatsapp_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_whatsapp_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        WhatsApp
                      </label>
                    </div>
                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_telegram_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_telegram_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_telegram_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_telegram_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_telegram_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Telegram
                      </label>
                    </div>

                    <div className="ml-2 flex items-center">
                      <input
                        id="artist_instag_id"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "artist_instag_id"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "artist_instag_id"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "artist_instag_id")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="artist_instag_id"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Instagram
                      </label>
                    </div>
                  </div>
                  <div className="flex justify-start items-center">
                    <div className="ml-2 flex items-center">
                      <input
                        id="tickets_platform_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "tickets_platform_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "tickets_platform_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "tickets_platform_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="tickets_platform_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Tickets
                      </label>
                    </div>

                    <div className="ml-2 flex items-center">
                      <input
                        id="merch_platform_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "merch_platform_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "merch_platform_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "merch_platform_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="merch_platform_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Merch
                      </label>
                    </div>

                    <div className="ml-2 flex items-center">
                      <input
                        id="shows_platform_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "shows_platform_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "shows_platform_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm("link_icons_list", "shows_platform_link")
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="shows_platform_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Shows
                      </label>
                    </div>

                    <div className="ml-2 flex items-center">
                      <input
                        id="community_platform_link"
                        type="checkbox"
                        checked={settings?.link_icons_list?.includes(
                          "community_platform_link"
                        )}
                        disabled={
                          !settings?.link_icons_list?.includes(
                            "community_platform_link"
                          ) && settings?.link_icons_list?.length >= 5
                        }
                        onClick={() =>
                          handleForm(
                            "link_icons_list",
                            "community_platform_link"
                          )
                        }
                        class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        for="community_platform_link"
                        className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                      >
                        Community
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pr-4 w-full flex mt-4 md:flex-col">
                <h3 className="text-gray-800 w-52  text-xs font-bold mb-2">
                  Pick Artist News:
                </h3>
                <div className="flex flex-wrap items-center">
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_news_on"
                      type="radio"
                      name="artist_news"
                      checked={settings?.artist_news}
                      onClick={() => handleForm("artist_news", true)}
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_news_on"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      On/Default
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_news_off"
                      type="radio"
                      name="artist_news"
                      checked={!settings?.artist_news}
                      onClick={() => handleForm("artist_news", false)}
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_news_off"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Off
                    </label>
                  </div>
                </div>
              </div>

              <div className="pr-4 w-full flex mt-4 md:flex-col">
                <h3 className="text-gray-800 w-52  text-xs font-bold mb-2">
                  Pick Stream/Social Embed 1:
                </h3>
                <div className="flex flex-wrap items-center">
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_spotfy_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed1 === "artist_spotfy_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed1", "artist_spotfy_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_spotfy_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Spotify
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_twitch_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed1 === "artist_twitch_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed1", "artist_twitch_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_twitch_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Twitch
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_vimeo_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed1 === "artist_vimeo_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed1", "artist_vimeo_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_vimeo_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Vimeo
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_aplmus_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed1 === "artist_aplmus_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed1", "artist_aplmus_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_aplmus_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Apple Music
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_sound_cloud_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed1 ===
                        "artist_sound_cloud_id"
                      }
                      onClick={() =>
                        handleForm(
                          "social_stream_embed1",
                          "artist_sound_cloud_id"
                        )
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_sound_cloud_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Soundcloud
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_deezer_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed1 === "artist_deezer_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed1", "artist_deezer_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_deezer_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Deezer
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_amazon_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed1 === "artist_amazon_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed1", "artist_amazon_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_amazon_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Amazon Music
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_youtub_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed1 === "artist_youtub_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed1", "artist_youtub_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_youtub_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Youtube
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_tiktok_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed1 === "artist_tiktok_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed1", "artist_tiktok_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_tiktok_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Tiktok
                    </label>
                  </div>
                </div>
              </div>

              <div className="pr-4 w-full flex mt-4 md:flex-col">
                <h3 className="text-gray-800 w-52  text-xs font-bold mb-2">
                  Pick Stream/Social Embed 2:
                </h3>
                <div className="flex flex-wrap items-center">
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_spotfy_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed2 === "artist_spotfy_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed2", "artist_spotfy_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_spotfy_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Spotify
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_twitch_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed2 === "artist_twitch_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed2", "artist_twitch_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_twitch_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Twitch
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_vimeo_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed2 === "artist_vimeo_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed2", "artist_vimeo_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_vimeo_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Vimeo
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_aplmus_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed2 === "artist_aplmus_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed2", "artist_aplmus_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_aplmus_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Apple Music
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_sound_cloud_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed2 ===
                        "artist_sound_cloud_id"
                      }
                      onClick={() =>
                        handleForm(
                          "social_stream_embed2",
                          "artist_sound_cloud_id"
                        )
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_sound_cloud_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Soundcloud
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_deezer_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed2 === "artist_deezer_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed2", "artist_deezer_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_deezer_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Deezer
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_amazon_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed2 === "artist_amazon_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed2", "artist_amazon_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_amazon_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Amazon Music
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_youtub_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed2 === "artist_youtub_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed2", "artist_youtub_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_youtub_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Youtube
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="artist_tiktok_id"
                      type="checkbox"
                      checked={
                        settings?.social_stream_embed2 === "artist_tiktok_id"
                      }
                      onClick={() =>
                        handleForm("social_stream_embed2", "artist_tiktok_id")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="artist_tiktok_id"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Tiktok
                    </label>
                  </div>
                </div>
              </div>

              <div className="pr-4 w-full flex mt-4 md:flex-col">
                <h3 className="text-gray-800 w-52  text-xs font-bold mb-2">
                  Pick Merch Offer:
                </h3>
                <div className="flex flex-wrap items-center">
                  <div className="ml-2 flex items-center">
                    <input
                      id="spotify"
                      type="checkbox"
                      checked={settings?.merch_offer === "spotify"}
                      onClick={() => handleForm("merch_offer", "spotify")}
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="spotify"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Spotify Merch TBC
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="shopify"
                      type="checkbox"
                      checked={settings?.merch_offer === "shopify"}
                      onClick={() => handleForm("merch_offer", "shopify")}
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="shopify"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Shopify Store TBC
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="merchbar"
                      type="checkbox"
                      checked={settings?.merch_offer === "merchbar"}
                      onClick={() => handleForm("merch_offer", "merchbar")}
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="merchbar"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Merchbar Store TBC
                    </label>
                  </div>
                </div>
              </div>

              <div className="pr-4 w-full flex mt-4 md:flex-col">
                <h3 className="text-gray-800 w-52  text-xs font-bold mb-2">
                  Pick Tickets Offer:
                </h3>
                <div className="flex flex-wrap items-center">
                  <div className="ml-2 flex items-center">
                    <input
                      id="ticketmaster"
                      type="checkbox"
                      checked={settings?.tickets_offer === "ticketmaster"}
                      onClick={() =>
                        handleForm("tickets_offer", "ticketmaster")
                      }
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="ticketmaster"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Ticketmaster TBC
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="bandsintown"
                      type="checkbox"
                      checked={settings?.tickets_offer === "bandsintown"}
                      onClick={() => handleForm("tickets_offer", "bandsintown")}
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="bandsintown"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      Bandsintown TBC
                    </label>
                  </div>
                  <div className="ml-2 flex items-center">
                    <input
                      id="seatgeek"
                      type="checkbox"
                      checked={settings?.tickets_offer === "seatgeek"}
                      onClick={() => handleForm("tickets_offer", "seatgeek")}
                      class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="seatgeek"
                      className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                    >
                      SeatGeek TBC
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              className="w-40 my-2 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default EditArtistProfileV2;
