import { supabase } from "../rtsvrf-database/SupabaseClient";
import { DataSharingPreference } from "../rtsvrf-utils/Constants";

export const getFanProfile = async (email) => {
  if (!email) return;
  const results = await supabase
    .from("tbl_staged_fan_user_profile")
    .select()
    .eq("fan_email", email)
    .single();
  return results.data;
};

export const getFanProfileById = async (id) => {
  if (!id) return;
  const results = await supabase
    .from("tbl_staged_fan_user_profile")
    .select()
    .eq("rtsvrf_fan_uuid", id)
    .single();
  return results.data;
};

export const getUserData = async (profile) => {
  if (!profile || !profile.fan_email) return;
  let user = await getFanProfile(profile.fan_email);
  if (!user) {
    await insertProfileData(profile);
    user = await getFanProfile(profile.fan_email);
    if (user) {
      localStorage.setItem("logIn", "true");
    }
    return user;
  } else return user;
};

export const insertProfileData = async (data) => {
  if (!data) return;
  let results = await supabase.from("tbl_staged_fan_user_profile").insert(data);
  return results;
};

export const getArtistData = async (artistIds, artists, userId) => {
  if (!artistIds) return;
  const savedArtists = await getFollowedArtists(artistIds);

  if (savedArtists?.length !== artistIds.length && artists) {
    for (let index = 0; index < artists.length; index++) {
      const artist = artists[index];
      if (!savedArtists.find((e) => e.artist_spotfy_id === artist.id)) {
        await insertArtistBasicProfile({
          artist_spotfy_id: artist.id,
          artist_name: artist.name,
          artist_origin_fan: userId,
          artist_origin_platform: "SPOTIFY",
        });
      }
    }
    const followedArtists = await getFollowedArtists(artistIds);
    return followedArtists;
  } else return savedArtists;
};

export const getFollowedArtists = async (artistIds) => {
  if (!artistIds) return;
  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .in("artist_spotfy_id", artistIds)
    .order("artist_name", { ascending: true });
  return results.data;
};

export const getArtistImage = async (artists) => {
  const results = await supabase
    .from("tbl_locked_artist_pics")
    .select()
    .in("rtsvrf_artist_id", artists);
  return results.data;
};

export const updateArtistImagePreference = async (artistSpotifyId, data) => {
  if (!data || !artistSpotifyId) return;
  const results = await supabase
    .from("tbl_locked_artist_pics")
    .update({
      ...data,
    })
    .eq("rtsvrf_artist_id", artistSpotifyId);
  return results.data;
};

export const getFullCountryName = async (code) => {
  if (!code) return;
  const results = await supabase
    .from("tbl_locked_countries")
    .select()
    .eq("iso2", code)
    .single();
  return results.data?.name;
};

export const getCountryNames = async () => {
  const results = await supabase.from("tbl_locked_countries").select();
  return results?.data;
};

export const insertArtistBasicProfile = async (data) => {
  if (!data) return;
  let results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .insert(data);
  return results;
};

export const getArtistsByNameSearch = async (searchString) => {
  if (!searchString) return;

  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .ilike("artist_name", `%${searchString}%`);
  return results.data;
};

export const getArtistsByRtsvrfId = async (artistIds) => {
  if (!artistIds) return;
  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .in("rtsvrf_artist_id", artistIds)
    .order("artist_name", { ascending: true });
  return results.data;
};

export const getArtistsByLabel = async (label) => {
  if (!label) return;
  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .filter("artist_label_connection", "cs", `{${label}}`);
  return results.data ?? [];
};

export const getLabelsByCode = async (codes) => {
  if (!codes) return;
  const results = await supabase
    .from("tbl_locked_labels")
    .select()
    .filter("artist_label_connection", "cs", `{${codes.join(",")}}`);
  return results.data ?? [];
};

export const getArtistEditorMappingsById = async (id) => {
  if (!id) return;
  const results = await supabase
    .from("tbl_staged_artist_editor_profile")
    .select()
    .eq("supabase_user_reference", id)
    .single();
  return results;
};
export const updateArtistEditorMappingsBySupabaseId = async (id, data) => {
  console.log(id, data, "log in");
  if (!id || !data) return;
  const results = await supabase
    .from("tbl_staged_artist_editor_profile")
    .update(data)
    .eq("supabase_user_reference", id);
  return results;
};

export const getUserAuth = async () => {
  const user = await supabase.auth.getUser();
  return user?.data?.user;
};

export const updateArtistEditorProfile = async (userData) => {
  const result = await supabase.auth.updateUser({
    data: { ...userData },
  });
  return result;
};

export const getArtistDataByRtsvrfId = async (artistId) => {
  if (!artistId) return;
  const results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .select()
    .eq("rtsvrf_artist_id", artistId)
    .single();
  return results.data;
};

export const getArtistPreferences = async (artistId) => {
  if (!artistId) return;
  const results = await supabase
    .from("tbl_staged_artist_profile_preferences")
    .select()
    .eq("rtsvrf_artist_id", artistId)
    .single();
  return results.data;
};

export const getArtistEditableFields = async (artistId) => {
  if (!artistId) return;
  const results = await supabase
    .from("tbl_locked_artist_editable_fields")
    .select()
    .eq("rtsvrf_artist_id", artistId)
    .single();
  return results.data;
};

export const updateArtistProfile = async (id, data) => {
  if (!data || !id) return;
  let results = await supabase
    .from("tbl_locked_artist_ids_stub_v2")
    .update({
      ...data,
    })
    .eq("rtsvrf_artist_id", id);
  return results;
};

export const updateArtistEditableFields = async (artistId, data) => {
  if (!artistId || !data) return;
  const results = await supabase
    .from("tbl_locked_artist_editable_fields")
    .upsert({
      ...data,
      updated_at: new Date(),
    });
  return results;
};

export const updateArtistPreferences = async (data) => {
  const results = await supabase
    .from("tbl_staged_artist_profile_preferences")
    .upsert({
      ...data,
    });
  return results;
};

export const insertLogHistory = async (data) => {
  if (!data) return;
  let results = await supabase.from("tbl_locked_logs_history").insert(data);
  return results;
};

export const getLogHistory = async (
  email,
  type,
  currentPage = 0,
  limit = 5
) => {
  console.log(currentPage, "current");
  if (!email || !type) return;
  let results = await supabase
    .from("tbl_locked_logs_history")
    .select()
    .order("timestamp", { ascending: false })
    .eq("email", email)
    .eq("type", type)
    .range(currentPage * limit, currentPage * limit + (limit - 1));
  return results;
};

export const getTotalLogHistory = async (email, type) => {
  if (!email || !type) return;
  let results = await supabase
    .from("tbl_locked_logs_history")
    .select("*", { count: "exact" })
    .eq("email", email)
    .eq("type", type);
  return results.count;
};

export const getArtistFollowersCount = async (
  artistSpotifyId,
  artistAppleId
) => {
  if (!artistSpotifyId) return;

  let results = await supabase
    .from("tbl_staged_fan_user_profile")
    .select("*", { count: "exact" })
    .or(
      `spotify_followed_artists_id.cs.{${artistSpotifyId}},apple_followed_artists_id.cs.{${artistAppleId}}`
    );
  return results.count;
};

export const getArtistTop3FollowersCount = async (artistId) => {
  if (!artistId) return;

  let results = await supabase
    .from("tbl_staged_fan_user_profile")
    .select("*", { count: "exact" })
    .or(
      `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId}`
    );

  return results.count;
};

export const getArtistTop1FollowersCount = async (artistId) => {
  if (!artistId) return;

  let results = await supabase
    .from("tbl_staged_fan_user_profile")
    .select("*", { count: "exact" })
    .eq("favourite_1", artistId);

  return results.count;
};

export const getFansCountBySegment = async (
  artistId,
  artistSpotifyId,
  artistAppleId,
  type
) => {
  if (!artistId) return;

  let results = await supabase
    .from("tbl_staged_fan_user_profile")
    .select("*", { count: "exact" })
    .eq("rtsvrf_fan_segment", type)
    .or(
      `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId},spotify_followed_artists_id.cs.{${artistSpotifyId}},apple_followed_artists_id.cs.{${artistAppleId}}`
    );
  return results.count;
};

export const getFansAddedByDateRange = async (
  artistId,
  artistSpotifyId,
  artistAppleId,
  fromValue,
  toValue
) => {
  if (!artistId) return;

  let results = await supabase
    .from("tbl_staged_fan_user_profile")
    .select("*", { count: "exact" })
    .or(
      `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId},spotify_followed_artists_id.cs.{${artistSpotifyId}},apple_followed_artists_id.cs.{${artistAppleId}}`
    )
    .filter("created_at", "gte", fromValue.toISOString())
    .filter("created_at", "lt", toValue.toISOString());
  return results.count;
};

const createfilteredQuery = (
  query,
  artistId,
  artistSpotifyId,
  artistAppleId,
  filters
) => {
  const notNullFilters = filters
    ?.filter((e) => e.type === "not")
    ?.map((e) => e.name);
  const sortingFields = filters
    ?.filter((e) => e.type === "order")
    ?.map((e) => e.name);
  const fieldFilters = filters
    ?.filter((e) => e.type === "filter")
    ?.map((e) => e.name);

  query = query.not(
    "data_sharing_preference",
    "in",
    `(${DataSharingPreference.SHARE_NO_DATA_WITH_FOLLOWED})`
  );
  if (notNullFilters?.length) {
    notNullFilters.forEach((filter) => {
      query = query.not(filter, "is", null);
    });
  }
  if (sortingFields?.length) {
    query = query.order(sortingFields);
  } else {
    query = query.order(["fan_name"]);
  }
  if (fieldFilters?.length) {
    fieldFilters.forEach((filter) => {
      if (filter === "favourite_1") {
        query = query.eq("favourite_1", artistId);
      } else if (filter === "top5Artists") {
        query = query.or(
          `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId}`
        );
      } else if (filter === "email" || filter === "push" || filter === "sms") {
        query = query.contains("custom_channel_list", [filter]);
      }
    });
  } else {
    query = query.or(
      `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId},spotify_followed_artists_id.cs.{${artistSpotifyId}},apple_followed_artists_id.cs.{${artistAppleId}}`
    );
  }
  return query;
};

export const getArtistTotalFansCount = async (
  artistId,
  artistSpotifyId,
  artistAppleId,
  filters
) => {
  if (!artistId || !artistSpotifyId) return;

  const query = supabase
    .from("tbl_staged_fan_user_profile")
    .select("rtsvrf_fan_uuid", { count: "exact" });

  const finalQuery = createfilteredQuery(
    query,
    artistId,
    artistSpotifyId,
    artistAppleId,
    filters
  );
  const results = await finalQuery;
  return results;
};

export const getArtistFansList = async (
  artistId,
  artistSpotifyId,
  artistAppleId,
  filters,
  currentPage = 0,
  limit = 25
) => {
  if (!artistId || !artistSpotifyId) return;
  let query = supabase.from("tbl_staged_fan_user_profile").select();

  let finalQuery = createfilteredQuery(
    query,
    artistId,
    artistSpotifyId,
    artistAppleId,
    filters
  );

  finalQuery = finalQuery.range(
    currentPage * limit,
    currentPage * limit + (limit - 1)
  );
  const results = await finalQuery;
  return results?.data;
};

export const sendMessagetoFans = async (listId, data) => {
  if (!data) return;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...data,
    }),
  };
  const result = await fetch(
    `${process.env.REACT_APP_INTERNAL_SERVER}/${listId}/send-message`,
    options
  );
  return result;
};

export const updateOrCreateArtistList = async (listId, artistName) => {
  if (!listId) return;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
    },
    body: JSON.stringify({
      name: artistName,
      listId,
    }),
  };
  const result = await fetch(
    `${process.env.REACT_APP_INTERNAL_SERVER}/update-list`,
    options
  );
  return result;
};

export const getUserSubscriberKey = async (user) => {
  if (!user) return;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${process.env.REACT_APP_INTERNAL_SERVER}/subscriber-key/${user.id}`,
    options
  );
  const result = await response.json();

  if (result?.subscriberKey) {
    const updatedUserMetadata = {
      ...user.user_metadata,
      suprsend_subscriber_id: result?.subscriberKey,
    };
    await updateArtistEditorProfile(updatedUserMetadata);
  }
  console.log(result);
  return result?.subscriberKey ?? null;
};
export const uploadImageToBucket = async (
  bucket,
  selectedImage,
  arrayBuffer
) => {
  if (!bucket) return { error: true, message: "Something went wrong!" };
  const time = new Date().getTime();
  const { data, error } = await supabase.storage
    .from(bucket)
    .upload(`public/${selectedImage.name}_${time}`, arrayBuffer, {
      contentType: selectedImage.type,
    });

  if (error) {
    console.error("Error uploading image:", error);
    return { error: true, message: error };
  } else {
    console.log("Image uploaded successfully:", data);
    const res = supabase.storage.from(bucket).getPublicUrl(data.path);
    return (
      res?.data?.publicUrl ?? {
        error: true,
        message: "Failed to upload an image",
      }
    );
  }
};

export const fetchLabelCodes = async () => {
  const { data, error } = await supabase.from("tbl_locked_labels").select();

  console.log("fetching label", data, error);
  if (error) {
    return [];
  }

  return data;
};

export const getArtistSettings = async (artistId) => {
  if (!artistId) return;
  const { data, error } = await supabase
    .from("tbl_locked_artist_news_config")
    .select()
    .eq("rtsvrf_artist_id", artistId)
    .single();

  return data ?? null;
};

export const updateArtistSettings = async (data) => {
  if (!data) return;
  let results = await supabase.from("tbl_locked_artist_news_config").upsert({
    ...data,
    updated_at: new Date(),
  });
  return results;
};

export const getArtistProfile2Preferences = async (artistId) => {
  if (!artistId) return;
  const { data, error } = await supabase
    .from("tbl_locked_artist_profile_v2_preferences")
    .select()
    .eq("rtsvrf_artist_id", artistId)
    .single();

  return data ?? null;
};

export const getArtistSubscription = async (artistId) => {
  if (!artistId) return;
  const { data, error } = await supabase
    .from("tbl_staged_artist_subsdata")
    .select()
    .eq("rtsvrf_artist_id", artistId)
    .single();

  return data ?? null;
};

export const updateArtistSubscription = async (artistId, data) => {
  if (!artistId) return;
  const result = await supabase
    .from("tbl_staged_artist_subsdata")
    .update(data)
    .eq("rtsvrf_artist_id", artistId)
    .single();

  return result;
};

export const updateArtistProfile2Preferences = async (data) => {
  if (!data) return;
  let results = await supabase
    .from("tbl_locked_artist_profile_v2_preferences")
    .upsert({
      ...data,
      updated_at: new Date(),
    });
  return results;
};

export const getArtistStats = async (artistId) => {
  if (!artistId) return;
  const { data, error } = await supabase
    .from("tbl_locked_artist_stats")
    .select()
    .eq("rtsvrf_artist_id", artistId)
    .single();

  return data ?? null;
};
