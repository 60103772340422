import React, { useEffect, useState } from "react";
import {
  getCountryNames,
  updateArtistEditorProfile,
} from "../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import moment from "moment";
import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";

function EditProfile({ user, reloadData, userIpData }) {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState();
  const [profile, setProfile] = useState({
    ...user?.user_metadata,
    updated_at: user?.updated_at,
    created_at: user?.created_at,
  });

  useEffect(() => {
    getCountryNames().then((res) => {
      if (res) setCountries(res);
    });
  }, []);

  const handleForm = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Save profile",
      action: "click",
      label: "Save profile",
    });
    const difference = Object.keys(profile).filter(
      (k) => profile[k] !== user?.user_metadata[k]
    );
    const details =
      difference?.map(
        (field) =>
          `${field} changed from ${user?.user_metadata[field]} to ${profile[field]}`
      ) || [];

    const result = await updateArtistEditorProfile(profile);
    setLoading(false);
    if (result.error) {
      toast.success("Failed to update profile! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess("Editor Profile Updated", {
        ...userIpData,
        email: user.email,
        timestamp: new Date(),
        details: details.join(","),
        type: "Editor",
      });
      reloadData();
    }
  };

  return (
    <>
      {profile ? (
        <>
          <h3 className="text-center text-lg my-2 font-bold text-white">
            My Editor Details
          </h3>
          <form class="w-full max-w-lg bg-gray-200 shadow-xl rounded-lg p-3">
            <div class="w-full mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Full Name
              </label>
              <input
                class="appearance-none text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="text"
                defaultValue={profile.name}
                onChange={(e) => handleForm("name", e.target.value)}
                placeholder="John Doe"
                required
              />
            </div>

            <div class="w-full mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Company
              </label>
              <input
                class="appearance-none text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="text"
                defaultValue={profile.company}
                onChange={(e) => handleForm("company", e.target.value)}
                placeholder="Company"
                required
              />
            </div>
            <div class="flex flex-wrap -mx-3 mb-1">
              <div class="w-1/2 md:w-full px-3 mb-3">
                <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                  City
                </label>
                <input
                  defaultValue={profile.city}
                  onChange={(e) => handleForm("city", e.target.value)}
                  class="appearance-none text-xs block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Albuquerque"
                />
              </div>

              <div class="w-1/2 md:w-full px-3 mb-3">
                <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                  Country
                </label>
                <div class="relative">
                  {countries.length ? (
                    <select
                      onChange={(e) => handleForm("country", e.target.value)}
                      defaultValue={profile.country}
                      class="block appearance-none text-xs w-full bg-white border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      {countries?.map((country) => (
                        <option value={country.iso2}>{country.name}</option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </div>
            </div>

            <div class="w-full  mb-3 ">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Phone Number
              </label>
              <input
                onChange={(e) => handleForm("phone_number", e.target.value)}
                class="appearance-none text-xs block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                defaultValue={profile.phone_number}
                placeholder="#123 456 789"
              />
            </div>

            <div class="flex flex-wrap -mx-3 mb-1">
              <div class="w-1/2 md:w-full px-3 mb-3 ">
                <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                  Joined On
                </label>
                <input
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  disabled={true}
                  defaultValue={
                    profile.created_at
                      ? moment(profile.created_at).format("DD MMMM YYYY")
                      : ""
                  }
                  placeholder="Date"
                />
              </div>

              <div class="w-1/2 md:w-full px-3 mb-3 ">
                <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                  Last Updated At
                </label>
                <input
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  disabled={true}
                  defaultValue={
                    profile.updated_at
                      ? moment(profile.updated_at).format("LLL")
                      : ""
                  }
                  placeholder="Date"
                />
              </div>
            </div>

            <div className="flex justify-center">
              <button
                type="button"
                onClick={(e) => handleSubmit(e)}
                class="w-40 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </>
      ) : null}
    </>
  );
}

export default EditProfile;
