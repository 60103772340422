import React, { useEffect, useState } from "react";
import { getCountryNames } from "../rtsvrf-actions/Actions";
import CsvDownloadButton from "react-json-to-csv";
import {
  CommunicationColumns,
  DataSharingPreference,
  DemographicColumns,
} from "../rtsvrf-utils/Constants";

const FanDataDownload = ({ fans, fetching, columns, artistId }) => {
  const [countries, setCountries] = useState([]);
  const [fanData, setFanData] = useState([]);
  useEffect(() => {
    const getCountries = async () => {
      const countryList = await getCountryNames();
      if (countryList?.length) {
        setCountries(countryList);
      }
    };
    getCountries();
  }, []);

  useEffect(() => {
    if (fans && !fetching) {
      const data = fans?.map((fan) => {
        const country = countries?.find(
          (e) => e.iso2 === fan?.temp_country
        )?.name;
        const showDemographicData =
          fan?.data_sharing_preference ===
            DataSharingPreference.SHARE_ALL_DATA_WITH_FOLLOWED ||
          fan?.data_sharing_preference ===
            DataSharingPreference.DEMOG_ONLY_WITH_FOLLOWED;
        const showCommunicationData =
          fan?.data_sharing_preference ===
            DataSharingPreference.SHARE_ALL_DATA_WITH_FOLLOWED ||
          fan?.data_sharing_preference ===
            DataSharingPreference.COMMS_ONLY_WITH_FOLLOWED;

        const otherColumnData = {};
        columns?.forEach((column) => {
          // const columnData = fieldMappings.find(
          //     (e) => e.column === columnType
          //   );
          //   const column=columnData?.name || columnType
          if (column === "temp_country") {
            otherColumnData.country = showDemographicData
              ? `${country || fan?.temp_country}`
              : "N/A";
          } else if (column === "city") {
            otherColumnData.city = showDemographicData ? `${fan.city}` : "N/A";
          } else if (column === "fan_status_level") {
            otherColumnData.fan_status_level = fan.fan_status_level || "-";
          } else if (column === "favourite_1") {
            otherColumnData.favourite_1 =
              fan[column] === artistId ? "Yes" : "No";
          } else if (column === "top5Artists") {
            otherColumnData.top5Artists =
              fan["favourite_1"] === artistId ||
              fan["favourite_2"] === artistId ||
              fan["favourite_3"] === artistId ||
              fan["favourite_4"] === artistId ||
              fan["favourite_5"] === artistId
                ? "Yes"
                : "No";
          } else if (
            column === "sms" ||
            column === "push" ||
            column === "email"
          ) {
            otherColumnData[column] = fan["custom_channel_list"]?.includes(
              column
            )
              ? "Yes"
              : "No";
          } else {
            if (DemographicColumns.includes(column)) {
              otherColumnData[column] = showDemographicData
                ? `${fan[column]}`
                : "N/A"
                ? "Yes"
                : "No";
            } else if (CommunicationColumns.includes(column)) {
              otherColumnData[column] = showCommunicationData
                ? `${fan[column]}`
                : "N/A";
            } else {
              otherColumnData[column] = fan[column];
            }
          }
        });

        return {
          name: showCommunicationData ? `${fan.fan_name}` : "N/A",
          email: showCommunicationData ? `${fan.fan_email}` : "N/A",
          ...otherColumnData,
        };
      });
      console.log(data, "data 123");
      setFanData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fans, columns, fetching, countries]);

  return (
    <>
      {fanData && (
        <button className="bg-white mr-1 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
          <CsvDownloadButton data={fanData ?? []} />
        </button>
      )}
    </>
  );
};

export default FanDataDownload;
