import React, { useState, useEffect } from "react";

import {
  getArtistDataByRtsvrfId,
  getArtistEditorMappingsById,
  getArtistFansList,
  getArtistImage,
  getArtistTotalFansCount,
  getUserAuth,
} from "../rtsvrf-actions/Actions";
import Loading from "../rtsvrf-components/Loading";
import Error from "../rtsvrf-components/Error";
import Navbar from "../rtsvrf-components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import ArtistProfile from "../rtsvrf-components/ArtistProfile";
import FansTable from "../rtsvrf-components/FansTable";
import Pagination from "../rtsvrf-components/Pagination";
import FanFilters from "../rtsvrf-components/FanFilters";
import Modal from "react-modal";
import { customStyles } from "../rtsvrf-utils/Constants";
import Chatbox from "../rtsvrf-components/ChatBox";

Modal.setAppElement("#root");

function LabelFanList({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState();
  const [artistEditor, setArtistEditor] = useState(null);
  const [delegatedArtist, setDelegatedArtist] = useState(null);
  const [artistImage, setArtistImage] = useState([]);
  const [filters, setFilters] = useState([]);
  const [columns, setColumns] = useState([
    "city",
    "temp_country",
    "fan_status_level",
  ]);
  const [fansList, setFansList] = useState(null);
  const [selectedFansForMessage, setSelectedFansForMessage] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const listPerPage = 25;
  const { userId, artistId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, artistId]);

  useEffect(() => {
    const fetchPaginatedFanData = async () => {
      if (!delegatedArtist?.artist_spotfy_id) return;
      await getArtistFans(
        delegatedArtist.artist_spotfy_id,
        delegatedArtist.artist_aplmus_id,
        currentPage
      );
    };
    fetchPaginatedFanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    const fetchFilteredFanData = async () => {
      console.log(filters, "filters");
      if (!delegatedArtist?.artist_spotfy_id) return;
      if (currentPage === 0) {
        await getArtistFans(
          delegatedArtist.artist_spotfy_id,
          delegatedArtist.artist_aplmus_id,
          currentPage
        );
      } else {
        setCurrentPage(0);
      }
    };
    fetchFilteredFanData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const reloadData = async () => {
    if (loading) return;
    setLoading(true);
    const userAuthData = await getUserAuth();
    setUser(userAuthData);
    if (userAuthData.id !== userId) {
      setHasError({
        title: "Access Denied",
        subtext:
          "You do not have enough permissions to check fans for this artist",
      });
      setLoading(false);
    }

    const result = await getArtistEditorMappingsById(userId);
    const userData = result.data;
    if (userData) {
      setArtistEditor(userData);
      const artist = await getArtistDataByRtsvrfId(artistId);
      if (
        userData.is_label_group_manager &&
        artist?.artist_label_connection?.includes(userData.label_group_code) &&
        artist?.share_data_with_label
      ) {
        setDelegatedArtist(artist);

        const images = await getArtistImage([artist?.rtsvrf_artist_id]);
        setArtistImage(
          images && images[0]
            ? images[0][images[0].artist_picture_preference]
            : null
        );
        setLoading(false);
        await getArtistFans(artist?.artist_spotfy_id, artist?.artist_aplmus_id);
      } else {
        setDelegatedArtist(null);
        setArtistImage(null);
        setHasError({
          title: "Access Denied",
          subtext:
            "You do not have enough permissions to check fans for this artist",
        });
        setLoading(false);
      }
    }

    setLoading(false);
  };

  const resetTable = () => {
    setColumns(["city", "temp_country", "fan_status_level"]);
    setFilters([]);
  };

  const getArtistFans = async (artistSpotifyId, artistAppleId, currentPage) => {
    if (!artistSpotifyId || fetching) return;
    setFetching(true);
    const totalFans = await getArtistTotalFansCount(
      artistId,
      artistSpotifyId,
      artistAppleId,
      filters
    );
    console.log(totalFans, "total");
    setSelectedFansForMessage(totalFans?.data || []);
    setTotal(totalFans?.count || 0);
    const allFans = await getArtistFansList(
      artistId,
      artistSpotifyId,
      artistAppleId,
      filters,
      currentPage,
      listPerPage
    );
    console.log(allFans, "all");
    setFetching(false);
    setFansList(allFans);
  };

  if (hasError) {
    return <Error title={hasError.title} subtext={hasError.subtext} />;
  }
  if (loading || !fansList) {
    return <Loading />;
  }

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
    >
      {user && (
        <Navbar
          user={user}
          links={[
            { label: "Home", link: `/artadm/${user?.id}` },
            {
              label: "Fans Summary",
              link: `/artadm/${user?.id}/fandata/${delegatedArtist.rtsvrf_artist_id}`,
            },
            { label: "Artist Fans", link: null },
            { label: delegatedArtist?.artist_name || "Artist", link: null },
          ]}
        />
      )}
      <div className="flex lg:flex-col justify-around lg:items-center">
        {user && (
          <ArtistProfile
            artistImage={artistImage}
            artist={delegatedArtist}
            hideQrCode={true}
          />
        )}
        <div className="flex flex-col my-10">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            Focus Artist {delegatedArtist?.artist_name}{" "}
            {delegatedArtist?.rtsvrf_artist_id}
          </h3>
          {fansList ? (
            <FansTable
              columns={columns}
              filters={filters}
              obfuscateData={true}
              setFilters={setFilters}
              fans={fansList}
              artistId={artistId}
              fetching={fetching}
            />
          ) : null}
          {fansList?.length ? (
            <div className="py-2 flex items-center justify-center">
              <Pagination
                listPerPage={listPerPage}
                total={total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          ) : null}

          <FanFilters columns={columns} setColumns={setColumns} />
          <div className="w-full flex justify-center items-center">
            <button
              className="bg-white mr-1 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
              onClick={() => resetTable()}
            >
              Reset View
            </button>

            <button
              className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
              onClick={() =>
                navigate(
                  `/artadm/${user?.id}/fandata/${delegatedArtist.rtsvrf_artist_id}?label=true`
                )
              }
            >
              Fan Data Summary
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Message"
      >
        <Chatbox
          userIpData={userIpData}
          artistEditorProfile={user}
          artistEditor={artistEditor}
          customized={true}
          selectedArtist={artistId}
          selectedArtistName={delegatedArtist?.artist_name}
          userIds={selectedFansForMessage}
        />
      </Modal>
    </div>
  );
}

export default LabelFanList;
