import React, { useEffect, useState } from "react";
import {
  getArtistImage,
  updateArtistImagePreference,
} from "../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import Modal from "react-modal";
import CloseIcon from "../rtsvrf-assets/icons/close.png";
import { toast } from "react-toastify";
import Info from "../rtsvrf-assets/icons/info.png";
import { logSuccess } from "../rtsvrf-actions/Logging";
import ImageUpload from "./ImageUpload";
import { customStyles } from "../rtsvrf-utils/Constants";
import { validURL } from "../rtsvrf-utils/Helpers";

Modal.setAppElement("#root");

function EditArtistProfileImage({ user, artist, reloadData, userIpData }) {
  const [loading, setLoading] = useState();
  const [artistImageData, setArtistImageData] = useState();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    const loadImageData = async () => {
      const images = await getArtistImage([artist?.rtsvrf_artist_id]);
      setArtistImageData(images && images[0] ? images[0] : null);
    };
    loadImageData();
  }, [artist]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handlePreference = (name, value) => {
    setArtistImageData({ ...artistImageData, [name]: value });
  };

  const savePreferences = async (data) => {
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Uplade profile picture",
      action: "click",
      label: "Uplade profile picture",
    });
    if (
      artistImageData?.artist_external_pix_link &&
      !validURL(artistImageData?.artist_external_pix_link)
    ) {
      toast.error("External image url is inavlid!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }
    const updateData = {
      ...artistImageData,
      artist_adm_pix_link: data ?? artistImageData.artist_adm_pix_link,
    };
    try {
      await updateArtistImagePreference(artist?.rtsvrf_artist_id, updateData);
      await logSuccess(
        `Profile picture updated - ${
          artist?.artist_name || artist?.rtsvrf_artist_id
        }`,
        {
          ...userIpData,
          email: user?.email,
          timestamp: new Date(),
          type: "Editor",
          details: `Profile picture preference updated to ${
            artistImageData?.artist_picture_preference
          } for ${artist?.artist_name || artist?.rtsvrf_artist_id} by ${
            user?.user_metadata?.name || user?.email || ""
          }`,
        }
      );
      closeModal();
      reloadData();
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setLoading(false);
  };

  return (
    <>
      <div class="p-2 text-center">
        <span onClick={openModal} className="underline text-sm cursor-pointer">
          Update Photo
        </span>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Profile Image Modal"
      >
        <div className="flex justify-between">
          <h2
            className="text-black cursor-pointer text-lg flex items-center"
            onClick={() => setOpen(true)}
          >
            <span className="mr-2">
              <img src={Info} alt="info" width={"20"} height={"20"} />
            </span>{" "}
            Update profile picture
          </h2>
          <span onClick={closeModal} className="cursor-pointer">
            <img src={CloseIcon} alt="close" width={"15"} height={"15"} />
          </span>
        </div>
        <div className="flex flex-col mt-2 p-1 w-full text-black items-center">
          <div className="w-full mb-1 flex items-center">
            <input
              id="artist_adm_pix_link"
              name="artist_picture_preference"
              type="radio"
              checked={
                artistImageData?.artist_picture_preference ===
                "artist_adm_pix_link"
              }
              onClick={() =>
                handlePreference(
                  "artist_picture_preference",
                  "artist_adm_pix_link"
                )
              }
              class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <label
              for="artist_adm_pix_link"
              className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
            >
              Use manual upload image
            </label>
          </div>

          {artistImageData?.artist_picture_preference ===
            "artist_adm_pix_link" && (
            <ImageUpload
              bucket={"bkt_staged_artist_profile"}
              handleImageUpload={savePreferences}
            />
          )}

          <div className="w-full my-1 flex items-center">
            <input
              id="artist_spotfy_pix_link"
              name="artist_picture_preference"
              type="radio"
              checked={
                artistImageData?.artist_picture_preference ===
                "artist_spotfy_pix_link"
              }
              onClick={() =>
                handlePreference(
                  "artist_picture_preference",
                  "artist_spotfy_pix_link"
                )
              }
              class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <label
              for="artist_spotfy_pix_link"
              className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
            >
              Use Spotify default image
            </label>
          </div>

          <div className="w-full my-1 flex items-center">
            <input
              id="artist_external_pix_link"
              name="artist_picture_preference"
              type="radio"
              checked={
                artistImageData?.artist_picture_preference ===
                "artist_external_pix_link"
              }
              onClick={() =>
                handlePreference(
                  "artist_picture_preference",
                  "artist_external_pix_link"
                )
              }
              class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <label
              for="artist_external_pix_link"
              className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
            >
              Use external image link (Image should be maximum 1000*1000 pixels)
            </label>
          </div>
          {artistImageData?.artist_picture_preference ===
            "artist_external_pix_link" && (
            <div class="w-full mb-6 ">
              <input
                class="appearance-none text-xs block w-full bg-white text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                onChange={(e) =>
                  handlePreference("artist_external_pix_link", e.target.value)
                }
                defaultValue={artistImageData?.artist_external_pix_link}
                placeholder="Enter external image link (JPG/JPEG or PNG supported)..."
              />
            </div>
          )}
        </div>
        {artistImageData?.artist_picture_preference !==
          "artist_adm_pix_link" && (
          <div className="w-full flex justify-center items-center">
            <button
              class="w-20 bg-black hover:bg-gray-800 text-white font-bold my-2 py-1 px-2 border border-gray-700 rounded"
              onClick={() => savePreferences()}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        style={customStyles}
        contentLabel="Settings Modal"
      >
        <div className="flex justify-between">
          <h2
            className="text-red-500 font-semibold text-lg flex items-center"
            onClick={() => setOpen(true)}
          >
            Important: Changing Artist Profile Photo
          </h2>
          <span onClick={() => setOpen(false)} className="cursor-pointer">
            <img src={CloseIcon} alt="close" width={"15"} height={"15"} />
          </span>
        </div>
        <div className="flex flex-col p-4 text-black items-center">
          <h2 className="text-black font-normal text-sm py-3">
            Changing the artist profile image will immediately apply this change
            to the artist profile which is visible in the ArtistVerified FanFeed
            application. In case this is not what you intend, you can switch
            back to the Spotify default artist image by coming back to this
            function and resetting the link.
          </h2>
        </div>
      </Modal>
    </>
  );
}

export default EditArtistProfileImage;
