import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import logo from "./rtsvrf-assets/avmainlogo.png";
import footerlogo from "./rtsvrf-assets/avfooter.jpg";
import ReactGA from "react-ga4";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SignIn from "./rtsvrf-pages/SignIn";

import Home from "./rtsvrf-pages/Home";

import { getUserAuth } from "./rtsvrf-actions/Actions";
import ArtistEdit from "./rtsvrf-pages/ArtistEdit";
import FanData from "./rtsvrf-pages/FanData";
import FanList from "./rtsvrf-pages/FanList";
import LabelFanList from "./rtsvrf-pages/LabelFanList";
import suprsend from "@suprsend/web-sdk";
import AccessError from "./rtsvrf-pages/AccessError";

suprsend.init(
  process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY,
  process.env.REACT_APP_SUPRSEND_WORKSPACE_SECRET
);
const App = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const [userIpData, setUserIpData] = useState();

  useEffect(() => {
    ReactGA.initialize("G-NPYFBSVLTR", {
      gaOptions: { streamId: "5606336493" },
    });
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
    const validateAccess = async () => {
      const user = await getUserAuth();
      console.log(user);
      if (location.pathname !== "/") {
        if (!user) {
          redirectToLogin();
        }
      } else {
        if (user?.id) navigate(`/artadm/${user?.id}`);
      }
    };

    const redirectToLogin = () => {
      localStorage.clear();
      navigate("/");
    };

    validateAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const getIp = async () => {
      const response = await fetch(
        `https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IP_KEY}`
      );
      const data = await response.json();
      setUserIpData({
        ip_address: data.ip,
        user_agent: navigator.userAgent,
      });
    };
    getIp();
  }, []);

  return (
    <>
      <header className="App-header">
        <table>
          <tr>
            <td>
              <h1>ArtistVerified Artist Admin App</h1>
            </td>
            <td>
              <img
                src={logo}
                width={"60"}
                height={"60"}
                className="App-logo"
                alt="logo"
                align="right"
              />
            </td>
          </tr>
        </table>
      </header>
      <div className="bg-gradient-to-r from-gray-400 to-gray-600">
        <Routes>
          <Route path="/" element={<SignIn userIpData={userIpData} />} />
          <Route
            path="/artadm/:id"
            element={<Home userIpData={userIpData} />}
          />
          <Route
            path="artadm/:userId/edit/:artistId"
            element={<ArtistEdit userIpData={userIpData} />}
          />
          <Route
            path="artadm/:userId/fandata/:artistId"
            element={<FanData userIpData={userIpData} />}
          />
          <Route
            path="artadm/:userId/fandata/:artistId/view"
            element={<FanList userIpData={userIpData} />}
          />
          <Route
            path="artadm/:userId/fandata/:artistId/manage"
            element={<LabelFanList userIpData={userIpData} />}
          />
          <Route
            path="/404"
            element={<AccessError userIpData={userIpData} />}
          />
        </Routes>
      </div>
      <center className="my-4">
        <img src={footerlogo} className="App-logo" alt="footerlogo" />
        <br />
        Copyright &copy; 2023 ArtistVerified Inc.
      </center>
      <ToastContainer />
    </>
  );
};

export default App;
