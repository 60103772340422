import React from "react";
import spotify from "../rtsvrf-assets/icons/007-spotify.png";
import facebook from "../rtsvrf-assets/icons/facebook.png";
import instagram from "../rtsvrf-assets/icons/instagram.png";
import twitter from "../rtsvrf-assets/icons/x.png";
import apple from "../rtsvrf-assets/icons/apple.png";
import youtube from "../rtsvrf-assets/icons/youtube.png";
import tiktok from "../rtsvrf-assets/icons/tiktok.png";
import amazon from "../rtsvrf-assets/icons/amazon-transparent.png";
import deezer from "../rtsvrf-assets/icons/deezer-transparent.png";
import threads from "../rtsvrf-assets/icons/threads.png";
import snapchat from "../rtsvrf-assets/icons/snapchat.png";
import mastodon from "../rtsvrf-assets/icons/mastodon.png";
import bluesky from "../rtsvrf-assets/icons/bluesky.png";
import signal from "../rtsvrf-assets/icons/signal.png";
import twitch from "../rtsvrf-assets/icons/twitch.png";
import vimeo from "../rtsvrf-assets/icons/vimeo.png";
import soundcloud from "../rtsvrf-assets/icons/soundcloud.png";
import whatsapp from "../rtsvrf-assets/icons/whatsapp.png";
import telegram from "../rtsvrf-assets/icons/telegram.png";
import discord from "../rtsvrf-assets/icons/discord.png";
import { useNavigate } from "react-router-dom";
import { formatFollowersCount } from "../rtsvrf-utils/Helpers";

function FanAnalytics({
  artistAnalytics,
  artist,
  isLabelManager,
  artistSocialStats,
}) {
  const navigate = useNavigate();
  return (
    <>
      <div className="my-10">
        <h3 className="text-center text-lg my-2 font-bold text-white">
          Focus Artist {artist?.artist_name} {artist?.rtsvrf_artist_id}
        </h3>
        <div class="w-full max-w-lg bg-gray-200 shadow-xl rounded-lg p-3">
          <div class="w-full text-center flex justify-center items-center md:w-full px-3 mb-4">
            <label class=" flex tracking-wide text-gray-500 text-xs font-bold">
              AV Total Followers
            </label>
            <div className="text-center ml-2 text-gray-400 text-xs font-semibold">
              <p>{formatFollowersCount(artistAnalytics?.allFollowers)} </p>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class=" flex tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={spotify}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="spotify"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>{formatFollowersCount(artistAnalytics?.allFollowers)} </p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={facebook}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="facebook"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.facebook_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={threads}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="threads"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.threads_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={snapchat}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="snapchat"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.snapchat_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={mastodon}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="mastodon"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.mastodon_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={bluesky}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="bluesky"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.bluesky_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={discord}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="discord"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.discord_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={signal}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="signal"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.signal_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={whatsapp}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="whatsapp"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.whatsapp_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={telegram}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="telegram"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.telegram_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={apple}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="apple"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.apple_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={instagram}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="instagram"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.instagram_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={youtube}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="youtube"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.youtube_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={tiktok}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="tiktok"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.tiktok_followers_latest
                  )}
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={amazon}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="amazon"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.amazon_followers_latest
                  )}
                </p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={twitter}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="twitter"
                />{" "}
                (Twitter) Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.twitter_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3 ">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={deezer}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="deezer"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.deezer_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={twitch}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="twitch"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.twitch_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={vimeo}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="vimeo"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.vimeo_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="flex  tracking-wide text-gray-500 text-xs font-bold mb-1">
                <img
                  src={soundcloud}
                  className="mr-1"
                  width={"16"}
                  height={"16"}
                  alt="soundcloud"
                />{" "}
                Followers
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(
                    artistSocialStats?.sound_cloud_followers_latest
                  )}{" "}
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-col flex-wrap -mx-3 mb-1 mt-2">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Fans Who Rate You #1
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>{formatFollowersCount(artistAnalytics?.top1Followers)}</p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Fans Who Rate You Top 5
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>{formatFollowersCount(artistAnalytics?.top3Followers)}</p>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Passive Fans
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(artistAnalytics?.passiveFansCount)}{" "}
                </p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Fans Added Last Day
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>{formatFollowersCount(artistAnalytics?.lastDayFanCount)} </p>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Active Fans
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>{formatFollowersCount(artistAnalytics?.activeFansCount)} </p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Fans Added Last Week
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(artistAnalytics?.lastWeekFanCount)}{" "}
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-1">
            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Super Fans
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>{formatFollowersCount(artistAnalytics?.superFansCount)}</p>
              </div>
            </div>

            <div class="w-1/2 flex md:w-full px-3 mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Fans Added Last Month
              </label>
              <div className="text-center ml-auto text-gray-400 text-xs font-semibold">
                <p>
                  {formatFollowersCount(artistAnalytics?.lastMonthFanCount)}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center my-2">
          <button
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
            onClick={() => navigate(isLabelManager ? "manage" : "view")}
          >
            Fan Data Drill Down
          </button>
        </div>
      </div>
    </>
  );
}

export default FanAnalytics;
