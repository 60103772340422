import React from "react";
import Select from "react-select";

function SelectDropDown({
  data,
  name,
  handleSelection,
  selectedOption,
  labelField,
  valueField,
  handleSearch,
  searchResults,
  isMulti,
  loading,
  isSearchable,
}) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      outerWidth: "275px",
      innerWidth: "275px",
      minWidth: "275px",
      maxWidth: "275px",
      innerHeight: "300px",
      outerHeight: "300px",
      maxHeight: "200px",
      overflow: "scroll",
    }),
  };

  return (
    <>
      <Select
        isMulti={isMulti ?? false}
        options={searchResults?.length > 0 ? searchResults : data}
        styles={customStyles}
        getOptionLabel={(option) => option[labelField]}
        isLoading={loading}
        getOptionValue={(option) => option[valueField]}
        placeholder="Search here..."
        onInputChange={(inputValue) => handleSearch && handleSearch(inputValue)}
        onChange={(selectedOption) => handleSelection(selectedOption, name)}
        required={false}
        value={selectedOption || null}
        isSearchable={isSearchable}
      />
    </>
  );
}

export default SelectDropDown;
