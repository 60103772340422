import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import {
  fetchLabelCodes,
  getLabelsByCode,
  updateArtistEditableFields,
  updateArtistProfile,
} from "../rtsvrf-actions/Actions";
import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";
import { DemoTopColumns } from "../rtsvrf-utils/Constants";
import { isArrayEqual } from "../rtsvrf-utils/Helpers";
import SelectDropDown from "./Select";

function EditMasterProfile({
  user,
  userIpData,
  artist,
  artistEditor,
  reloadData,

  editableFieldsStatus,
}) {
  const [loading, setLoading] = useState();
  const [labelCodes, setLabelCodes] = useState();
  const [profile, setProfile] = useState(artist);
  const [selectedLabelCodes, setSelectedLabelCodes] = useState([]);
  const [fieldsStatus, setFieldsStatus] = useState();

  useEffect(() => {
    const fetchLabelCodesList = async () => {
      const result = await fetchLabelCodes();
      setLabelCodes(result);
    };
    fetchLabelCodesList();
  }, []);

  useEffect(() => {
    const fetchArtistLabelCodes = async () => {
      if (artist?.artist_label_connection?.length) {
        const result = await getLabelsByCode(artist.artist_label_connection);
        setSelectedLabelCodes(result);
      }
    };
    fetchArtistLabelCodes();
  }, [artist]);

  useEffect(() => {
    setFieldsStatus(editableFieldsStatus);
  }, [editableFieldsStatus]);

  const handleForm = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };

  const handleStatus = (name, value) => {
    setFieldsStatus({ ...fieldsStatus, [name]: value });
  };

  const handleDQRStatus = async (name, value) => {
    let filteredColumns = [];
    if (DemoTopColumns.includes(name)) {
      filteredColumns = DemoTopColumns.filter((e) => e !== name).reduce(
        (acc, item) => {
          acc[item] = false;
          return acc;
        },
        {}
      );
    }
    const profileResult = await updateArtistProfile(artist?.rtsvrf_artist_id, {
      ...profile,
      [name]: value,
      ...filteredColumns,
      dqr_completed_datetime:
        name === "dqr_completed_status"
          ? new Date()
          : profile?.dqr_completed_datetime,
    });
    if (profileResult?.error) {
      toast.error("Failed to update! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess(`${artist?.artist_name || "Artist"} Profile Updated`, {
        ...userIpData,
        email: user.email,
        timestamp: new Date(),
        details: `${artist?.artist_name || "Artist"}:${
          artist?.rtsvrf_artist_id
        } profile: ${name} changed from ${profile[name]} to ${value}  ${
          name === "dqr_completed_status"
            ? `and dqr_completed_datetime changed from ${
                profile?.dqr_completed_datetime
              } to ${new Date()}`
            : ""
        } `,
        type: artist?.rtsvrf_artist_id,
      });
      reloadData();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Save Artist Profile",
      action: "click",
      label: "Save Artist Profile",
    });
    console.log(profile, fieldsStatus);
    let extraProfileFields = {};
    let profileDifference = Object.keys(profile).filter(
      (k) => profile[k] !== artist[k] && k !== "artist_label_connection"
    );

    if (
      !isArrayEqual(
        profile.artist_label_connection,
        artist.artist_label_connection
      )
    ) {
      profileDifference.push("artist_label_connection");
    }
    if (profileDifference.includes("artist_label_connection")) {
      extraProfileFields = { artist_label_connection_datetime: new Date() };
      profileDifference.push("artist_label_connection_datetime");
    }
    console.log(profileDifference, profile);

    const editableFieldsDifference = Object.keys(fieldsStatus).filter(
      (k) => fieldsStatus[k] !== editableFieldsStatus[k]
    );

    const profileDetails =
      profileDifference?.map(
        (field) =>
          `${field} changed from ${artist[field] || null} to ${
            extraProfileFields[field] || profile[field] || null
          }`
      ) || [];

    const editableFieldsDetails =
      editableFieldsDifference?.map(
        (field) =>
          `${field} changed from ${editableFieldsStatus[field] || null} to ${
            fieldsStatus[field] || null
          }`
      ) || [];

    const profileResult = await updateArtistProfile(artist?.rtsvrf_artist_id, {
      ...profile,
      ...extraProfileFields,
    });

    let fieldsStatusResult;
    if (fieldsStatus)
      fieldsStatusResult = await updateArtistEditableFields(
        artist?.rtsvrf_artist_id,
        fieldsStatus
      );

    setLoading(false);
    if (profileResult?.error || fieldsStatusResult?.error) {
      toast.error("Failed to update profile! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess(
        `${artist?.artist_name || "Artist"} Master Profile Updated`,
        {
          ...userIpData,
          email: user.email,
          timestamp: new Date(),
          details: `${artist?.artist_name || "Artist"}:${
            artist?.rtsvrf_artist_id
          } profile:  ${[...profileDetails, ...editableFieldsDetails].join(
            ","
          )}`,
          type: artist?.rtsvrf_artist_id,
        }
      );
      reloadData();
    }
  };
  const selectDropdownOption = (selectedOption, name) => {
    if (
      profile?.artist_label_connection?.find(
        (label) => label === selectedOption.rtsvrf_label_code
      )
    ) {
      toast.error("Select unique code!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    console.log(selectedOption, "selectedOption label");
    setSelectedLabelCodes(selectedOption);
    const codes = selectedOption?.map((e) => e.rtsvrf_label_code);
    setProfile({ ...profile, artist_label_connection: codes });
  };

  return (
    <>
      {profile ? (
        <div className="my-10 w-full ml-5 lg:ml-0">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            Focus Artist - {artist?.artist_name} - {artist?.rtsvrf_artist_id}
          </h3>
          {artistEditor?.admindqr_delegated_artists && (
            <form className="w-full my-2 flex items-baseline md:flex-col justify-around bg-gray-200 shadow-xl rounded-lg p-4">
              <div className="pr-4 w-full">
                <div className="w-full mb-6 flex items-center">
                  <label
                    for="dqr-checkbox"
                    className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                  >
                    DQR Completed Status
                  </label>

                  <input
                    id="dqr-checkbox"
                    type="checkbox"
                    checked={profile?.dqr_completed_status}
                    onClick={() =>
                      handleDQRStatus(
                        "dqr_completed_status",
                        !profile?.dqr_completed_status
                      )
                    }
                    class="w-4 h-4 -mt-2 ml-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                </div>
              </div>
              <div className="pr-4 w-full">
                <div className="w-full mb-6 flex items-center">
                  <label className="block tracking-wide text-gray-500 text-xs font-bold mb-2">
                    Demo Status
                  </label>

                  <select
                    onChange={(e) => handleDQRStatus(e.target.value, true)}
                    defaultValue={
                      profile?.demo_top100
                        ? "demo_top100"
                        : profile?.demo_top300
                        ? "demo_top300"
                        : profile?.demo_top999
                        ? "demo_top999"
                        : null
                    }
                    class="block appearance-none text-xs w-full bg-white border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option disabled value={null}>
                      Select demo status
                    </option>
                    <option value={"demo_top100"}>Demo Top 100</option>
                    <option value={"demo_top300"}>Demo Top 300</option>
                    <option value={"demo_top999"}>Demo Top 999</option>
                  </select>
                </div>
              </div>
            </form>
          )}

          <form className="w-full flex items-baseline md:flex-col justify-around bg-gray-200 shadow-xl rounded-lg p-4">
            <div className="pr-4 w-full">
              <div className="w-full mb-6 flex items-center">
                <div className="flex items-center justify-start">
                  <label className="block w-20 tracking-wide text-gray-500 text-xs font-bold mb-2">
                    Publish
                  </label>
                  <input
                    className={
                      fieldsStatus?.published
                        ? "mr-2 mt-[-0.7rem] ml-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-blue-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[0] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-blue-500 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[0] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[0] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                        : "mr-2 mt-[-0.7rem] ml-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[0] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[0] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[0] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    }
                    type="checkbox"
                    role="switch"
                    defaultChecked={fieldsStatus?.published}
                    onClick={(e) => handleStatus("published", e.target.checked)}
                  />
                </div>
                <input
                  className="invisible appearance-none mt-2 text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                />
              </div>
            </div>
            <div className="pr-4 w-full">
              <div className="w-full mb-6 flex items-center">
                <div className="flex items-center justify-start">
                  <label className="block w-20 tracking-wide text-gray-500 text-xs font-bold mb-2">
                    Share Data with These Groups
                  </label>
                  <input
                    className={
                      profile?.share_data_with_label
                        ? "mr-2 mt-[-0.7rem] ml-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-blue-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[0] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-blue-500 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[0] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[0] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                        : "mr-2 mt-[-0.7rem] ml-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[0] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[0] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[0] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    }
                    type="checkbox"
                    role="switch"
                    defaultChecked={profile?.share_data_with_label}
                    onClick={(e) =>
                      handleForm("share_data_with_label", e.target.checked)
                    }
                  />
                </div>
                <SelectDropDown
                  data={labelCodes}
                  name={"rtsvrf_label_code"}
                  isMulti={true}
                  selectedOption={selectedLabelCodes || []}
                  handleSelection={selectDropdownOption}
                  labelField={"rtsvrf_label_name"}
                  valueField={"rtsvrf_label_code"}
                  loading={!labelCodes}
                  isSearchable={false}
                />
              </div>
            </div>
          </form>
          <div className="flex justify-center">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              className="w-40 my-2 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default EditMasterProfile;
