import React, { useEffect, useState } from "react";

import "./Chat.css";
import "../../node_modules/react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";

const InboxNotification = ({
  artistName,
  loading,
  createMessage,
  artistId,
  currentTab,
}) => {
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    setInputText("");
  }, [artistId, currentTab]);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setInputText(inputText + "\n"); // Append a line break
      event.preventDefault(); // Prevent the default Enter key behavior (new line)
    }
  };

  const handleSendMessage = async () => {
    if (!inputText) {
      toast.error("Add message properly!");
      return;
    }
    await createMessage({
      title: artistName,
      body: inputText,
      type: "inbox",
    });
  };
  return (
    <>
      <div class="w-full mb-3">
        <label class="block  tracking-wide text-white text-xs font-bold mb-1">
          Message{" "}
          <span className="text-xs font-bold text-gray-800">
            &nbsp;(Should not exceed 200 words)
          </span>
        </label>
        <textarea
          class="appearance-none text-xs block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          value={inputText}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Enter message for fans..."
          rows={10}
          cols={100}
        />
      </div>
      <div className="w-full text-center">
        <button
          className="bg-white w-40 mt-2 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
          onClick={() => handleSendMessage()}
        >
          {loading ? "Sending..." : "Send"}
        </button>
      </div>
    </>
  );
};

export default InboxNotification;
