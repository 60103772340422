import React from "react";
import formats from "./ToolbarOptions.js";
import ImgUpload from "../rtsvrf-assets/icons/gallery.png";

const renderOptions = (formatData) => {
  const { className, options } = formatData;
  return (
    <select className={className}>
      <option selected="selected"></option>
      {options.map((value) => {
        return <option value={value}></option>;
      })}
    </select>
  );
};
const renderSingle = (formatData) => {
  const { className, value } = formatData;
  return <button className={className} value={value}></button>;
};
const CustomToolbar = ({ onImageUpload }) => {
  return (
    <div id="toolbar">
      {formats?.map((classes) => {
        return (
          <span className="ql-formats" key={classes[0].className}>
            {classes.map((formatData) => {
              return formatData.options ? (
                renderOptions(formatData)
              ) : formatData.className === "ql-image-upload" ? (
                <button
                  className={formatData.className}
                  key={formatData.className}
                  onClick={onImageUpload}
                >
                  <img
                    src={ImgUpload}
                    alt="img-upload"
                    width={"16"}
                    height={"14"}
                  />
                </button>
              ) : (
                renderSingle(formatData)
              );
            })}
          </span>
        );
      })}
    </div>
  );
};
export default CustomToolbar;
