import React, { useState, useEffect } from "react";

import {
  getArtistDataByRtsvrfId,
  getArtistEditorMappingsById,
  getArtistFollowersCount,
  getArtistImage,
  getArtistStats,
  getArtistTop1FollowersCount,
  getArtistTop3FollowersCount,
  getFansAddedByDateRange,
  getFansCountBySegment,
  getUserAuth,
} from "../rtsvrf-actions/Actions";
import Loading from "../rtsvrf-components/Loading";
import Error from "../rtsvrf-components/Error";
import Navbar from "../rtsvrf-components/Navbar";
import { useParams, useSearchParams } from "react-router-dom";
import ArtistProfile from "../rtsvrf-components/ArtistProfile";
import FanAnalytics from "../rtsvrf-components/FanAnalytics";

function FanData() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [user, setUser] = useState();
  const [isLabelManager, setIsLabelManager] = useState();
  const [delegatedArtist, setDelegatedArtist] = useState(null);
  const [artistImage, setArtistImage] = useState([]);
  const [artistAnalytics, setArtistAnalytics] = useState(null);
  const [artistSocialStats, setArtistSocialStats] = useState(null);
  const { userId, artistId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    reloadData();
    const label = searchParams.get("label");
    if (label) {
      setIsLabelManager(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, artistId]);

  const reloadData = async () => {
    if (loading) return;
    setLoading(true);
    const userAuthData = await getUserAuth();
    setUser(userAuthData);
    console.log(userAuthData, userId, userAuthData.id !== userId);
    if (userAuthData?.id !== userId) {
      setHasError({
        title: "Access Denied",
        subtext: "You do not have enough permissions to check this artist",
      });
      setLoading(false);
    }

    const result = await getArtistEditorMappingsById(userId);
    const userData = result.data;
    if (userData) {
      const artist = await getArtistDataByRtsvrfId(artistId);
      if (
        (userData?.fandata_delegated_artists &&
          userData.fandata_delegated_artists.find(
            (artist) => artist === artistId
          )) ||
        (userData.is_label_group_manager &&
          artist?.share_data_with_label &&
          artist?.artist_label_connection?.includes(userData.label_group_code))
      ) {
        setDelegatedArtist(artist);
        const images = await getArtistImage([artist?.rtsvrf_artist_id]);
        setArtistImage(
          images && images[0]
            ? images[0][images[0].artist_picture_preference]
            : null
        );
        setLoading(false);
        await getArtistAnalytics(
          artist?.artist_spotfy_id,
          artist?.artist_aplmus_id
        );
      } else {
        setDelegatedArtist(null);
        setArtistImage(null);
        setHasError({
          title: "Access Denied",
          subtext: "You do not have enough permissions to check this artist",
        });
        setLoading(false);
      }
    }

    setLoading(false);
  };

  const getArtistAnalytics = async (artistSpotifyId, artistAppleId) => {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    const allFollowers = await getArtistFollowersCount(
      artistSpotifyId,
      artistAppleId
    );
    const socialStats = await getArtistStats(artistId);
    if (socialStats) {
      setArtistSocialStats(socialStats);
    }
    const top3Followers = await getArtistTop3FollowersCount(artistId);
    const top1Followers = await getArtistTop1FollowersCount(artistId);
    const superFansCount = await getFansCountBySegment(
      artistId,
      artistSpotifyId,
      artistAppleId,
      "Super"
    );
    const activeFansCount = await getFansCountBySegment(
      artistId,
      artistSpotifyId,
      artistAppleId,
      "Active"
    );
    const passiveFansCount = await getFansCountBySegment(
      artistId,
      artistSpotifyId,
      artistAppleId,
      "Passive"
    );
    const currentDate = new Date();
    const lastDayDate = new Date(currentDate);
    lastDayDate.setDate(currentDate.getDate() - 1);

    const lastDayFanCount = await getFansAddedByDateRange(
      artistId,
      artistSpotifyId,
      artistAppleId,
      lastDayDate,
      currentDate
    );
    const lastWeekDate = new Date(currentDate);
    lastWeekDate.setDate(currentDate.getDate() - 7);

    const lastWeekFanCount = await getFansAddedByDateRange(
      artistId,
      artistSpotifyId,
      artistAppleId,
      lastWeekDate,
      currentDate
    );

    const lastMonthDate = new Date(currentDate);
    lastMonthDate.setMonth(currentDate.getMonth() - 1);

    const lastMonthFanCount = await getFansAddedByDateRange(
      artistId,
      artistSpotifyId,
      artistAppleId,
      lastMonthDate,
      currentDate
    );

    setArtistAnalytics({
      allFollowers: allFollowers || "N/A",
      top3Followers: top3Followers || "N/A",
      top1Followers: top1Followers || "N/A",
      superFansCount: superFansCount || "N/A",
      activeFansCount: activeFansCount || "N/A",
      passiveFansCount: passiveFansCount || "N/A",
      lastDayFanCount: lastDayFanCount || "N/A",
      lastWeekFanCount: lastWeekFanCount || "N/A",
      lastMonthFanCount: lastMonthFanCount || "N/A",
    });
  };

  if (hasError) {
    return <Error title={hasError.title} subtext={hasError.subtext} />;
  }
  if (loading || !artistAnalytics) {
    return <Loading />;
  }

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
    >
      {user && (
        <Navbar
          user={user}
          links={[
            { label: "Home", link: `/artadm/${user?.id}` },
            { label: "Artist Fan Data", link: null },
            { label: delegatedArtist?.artist_name || "Artist", link: null },
          ]}
        />
      )}
      <div className="flex lg:flex-col justify-around lg:items-center">
        {user && (
          <ArtistProfile
            artistImage={artistImage}
            artist={delegatedArtist}
            hideQrCode={true}
          />
        )}

        {artistAnalytics ? (
          <FanAnalytics
            artistAnalytics={artistAnalytics}
            artist={delegatedArtist}
            artistSocialStats={artistSocialStats}
            isLabelManager={isLabelManager}
          />
        ) : null}
      </div>
    </div>
  );
}

export default FanData;
