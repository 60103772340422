import React, { useEffect, useState } from "react";
import user from "../rtsvrf-assets/icons/user.png";
import sort from "../rtsvrf-assets/icons/sort.png";
import filter from "../rtsvrf-assets/icons/filter.png";
import { getCountryNames } from "../rtsvrf-actions/Actions";
import Loading from "./Loading";
import {
  CommunicationColumns,
  DataSharingPreference,
  DemographicColumns,
  fieldMappings,
} from "../rtsvrf-utils/Constants";
import { hideSensitiveInfo } from "../rtsvrf-utils/Helpers";

const FansTable = ({
  fans,
  fetching,
  obfuscateData,
  columns,
  setFilters,
  filters,
  artistId,
}) => {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const getCountries = async () => {
      const countryList = await getCountryNames();
      if (countryList?.length) {
        setCountries(countryList);
      }
    };
    getCountries();
  }, []);

  const handleFilters = (filter) => {
    let list = [...filters];
    if (list.find((field) => field.name === filter.name)) {
      list = list.filter((field) => field.name !== filter.name);
      setFilters(list);
    } else {
      setFilters([...list, filter]);
    }
  };

  if (!fans || fetching) {
    return (
      <div className="text-center border border-solid rounded-md text-lg py-6 font-bold text-white">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div class="overflow-x-auto">
        <div class="flex flex-col justify-center items-center overflow-x-auto">
          <div class="overflow-x-auto sm:max-w-xs md:max-w-sm lg:max-w-2xl xl:max-w-lg 2xl:max-w-2xl min-2xl:max-w-5xl min-4xl:max-w-full rounded-lg border border-gray-200 shadow-md">
            <table class="sm:block sm:overflow-x-auto sm:whitespace-nowrap xs:max-w-xs border-collapse max-w-lg bg-white text-left text-sm text-gray-500">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-4 font-medium text-gray-900">
                    Name
                  </th>
                  {columns?.map((column) => {
                    const columnData = fieldMappings.find(
                      (e) => e.column === column
                    );
                    const active =
                      filters.find((field) => field.name === column) || false;
                    return (
                      <th
                        scope="col"
                        class={`px-6 py-4 whitespace-nowrap font-medium ${
                          active ? "text-blue-600" : "text-gray-900"
                        }`}
                      >
                        <span className="flex items-center">
                          {columnData?.name || column}
                          {columnData?.type === "order" && (
                            <img
                              src={sort}
                              onClick={() =>
                                handleFilters({
                                  name: column,
                                  type: columnData.type,
                                })
                              }
                              className="ml-1 cursor-pointer"
                              width={"15"}
                              height={"15"}
                              alt="sort"
                            />
                          )}
                          {columnData?.type !== "order" && (
                            <img
                              src={filter}
                              onClick={() =>
                                handleFilters({
                                  name: column,
                                  type: columnData.type,
                                })
                              }
                              className="ml-1 cursor-pointer"
                              width={"15"}
                              height={"15"}
                              alt="filter"
                            />
                          )}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-100 border-t border-gray-100">
                {fans ? (
                  fans?.map((fan) => {
                    const country = countries?.find(
                      (e) => e.iso2 === fan?.temp_country
                    )?.name;
                    const showDemographicData =
                      fan?.data_sharing_preference ===
                        DataSharingPreference.SHARE_ALL_DATA_WITH_FOLLOWED ||
                      fan?.data_sharing_preference ===
                        DataSharingPreference.DEMOG_ONLY_WITH_FOLLOWED;
                    const showCommunicationData =
                      fan?.data_sharing_preference ===
                        DataSharingPreference.SHARE_ALL_DATA_WITH_FOLLOWED ||
                      fan?.data_sharing_preference ===
                        DataSharingPreference.COMMS_ONLY_WITH_FOLLOWED;
                    return (
                      <tr>
                        <th class="px-6 py-4 font-normal text-gray-900">
                          <div className="flex md:flex-col gap-3 ">
                            <img
                              width={"40"}
                              height={"40"}
                              class="rounded-full object-cover object-center"
                              src={
                                fan?.fan_picture_link &&
                                DataSharingPreference.SHARE_ALL_DATA_WITH_FOLLOWED
                                  ? fan.fan_picture_link
                                  : user
                              }
                              alt="img"
                            />
                            <div class="text-sm">
                              <div class="font-medium text-gray-700">
                                {obfuscateData
                                  ? `${hideSensitiveInfo(fan.fan_name)}`
                                  : showCommunicationData
                                  ? `${fan.fan_name}`
                                  : "N/A"}
                              </div>
                              <div class="text-gray-400">
                                {obfuscateData
                                  ? `${hideSensitiveInfo(fan.fan_email)}`
                                  : showCommunicationData
                                  ? `${fan.fan_email}`
                                  : "N/A"}
                              </div>
                            </div>
                          </div>
                        </th>

                        {columns?.map((column) => {
                          if (column === "temp_country") {
                            return (
                              <td class="px-6 py-4">
                                {showDemographicData
                                  ? `${country || fan?.temp_country}`
                                  : "N/A"}
                              </td>
                            );
                          } else if (column === "city") {
                            return (
                              <td class="px-6 py-4">
                                <span class="items-center whitespace-nowrap gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                                  {showDemographicData ? `${fan.city}` : "N/A"}
                                </span>
                              </td>
                            );
                          } else if (column === "fan_status_level") {
                            return (
                              <td class="px-6 py-4">
                                <div class="flex gap-2">
                                  {fan?.fan_status_level && (
                                    <span class="items-center gap-1 rounded-full !bg-blue-50 px-2 py-1 text-xs font-semibold !text-blue-600">
                                      {fan.fan_status_level || "-"}
                                    </span>
                                  )}
                                </div>
                              </td>
                            );
                          } else if (column === "favourite_1") {
                            return (
                              <td class="px-6 py-4">
                                {fan[column] === artistId ? "Yes" : "No"}
                              </td>
                            );
                          } else if (column === "top5Artists") {
                            return (
                              <td class="px-6 py-4">
                                {fan["favourite_1"] === artistId ||
                                fan["favourite_2"] === artistId ||
                                fan["favourite_3"] === artistId ||
                                fan["favourite_4"] === artistId ||
                                fan["favourite_5"] === artistId
                                  ? "Yes"
                                  : "No"}
                              </td>
                            );
                          } else if (
                            column === "sms" ||
                            column === "push" ||
                            column === "email"
                          ) {
                            return (
                              <td class="px-6 py-4">
                                {fan["custom_channel_list"]?.includes(column)
                                  ? "Yes"
                                  : "No"}
                              </td>
                            );
                          } else {
                            if (DemographicColumns.includes(column)) {
                              return (
                                <td class="px-6 py-4">
                                  {showDemographicData
                                    ? `${fan[column]}`
                                    : "N/A"}
                                </td>
                              );
                            } else if (CommunicationColumns.includes(column)) {
                              return (
                                <td class="px-6 py-4">
                                  {obfuscateData
                                    ? hideSensitiveInfo(fan[column])
                                    : showCommunicationData
                                    ? `${fan[column]}`
                                    : "N/A"}
                                </td>
                              );
                            }
                            return (
                              <td class="px-6 py-4">
                                {" "}
                                {column === "wallet_address" && obfuscateData
                                  ? hideSensitiveInfo(fan[column])
                                  : fan[column]}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <h3 className="text-center border border-solid rounded-md text-lg py-6 font-bold text-white">
                    No fans Found
                  </h3>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default FansTable;
