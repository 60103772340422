import React, { useState, useEffect } from "react";

import { getArtistEditorMappingsById } from "../rtsvrf-actions/Actions";

import Loading from "../rtsvrf-components/Loading";

import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";

import Error from "../rtsvrf-components/Error";

Modal.setAppElement("#root");

function AccessError({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const reloadData = async () => {
    setLoading(true);

    const result = await getArtistEditorMappingsById(id);
    console.log(result);
    const userData = result?.data;
    console.log(userData, "userData");
    if (userData) {
      navigate(`/artadm/${id}`);
    }
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <Error
      title={"Access Denied"}
      subtext={"You do not have access to manage this App"}
    />
  );
}

export default AccessError;
