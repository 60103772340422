import React, { useState } from "react";
import UserIcon from "../rtsvrf-assets/icons/user.png";
import { getFullCountryName } from "../rtsvrf-actions/Actions";
import verified from "../rtsvrf-assets/icons/av-tick.png";
import EditProfileImage from "./EditProfileImage";

function Profile({ user, reloadData, userIpData }) {
  const [country, setCountry] = useState();
  const [loading, setLoading] = useState();

  if (!country && !loading) {
    setLoading(true);
    getFullCountryName(user?.user_metadata?.country).then((res) => {
      setCountry(res);
      setLoading(false);
    });
  }

  return (
    <>
      {user ? (
        <div
          className={`flex flex-col my-10 md:justify-center md:items-center`}
        >
          <h3 className="max-w-sm w-72 sm:w-48 text-center text-lg my-2 font-bold text-white">
            My Editor Profile
          </h3>

          <div className="max-w-sm w-72 sm:w-48">
            <div className="bg-white shadow-xl rounded-lg p-6">
              <div className="photo-wrapper p-2">
                {user?.user_metadata?.profile_picture_link ? (
                  <img
                    className="w-44 h-44 xl:w-36 xl:h-36 sm:w-32 sm:h-32 rounded-full mx-auto"
                    src={user?.user_metadata?.profile_picture_link}
                    alt="u"
                  />
                ) : (
                  <img
                    className="w-44 h-44 xl:w-36 xl:h-36 rounded-full mx-auto"
                    src={UserIcon}
                    alt="profile-img"
                  />
                )}
                <EditProfileImage
                  user={user}
                  reloadData={reloadData}
                  userIpData={userIpData}
                />
              </div>
              <div className="p-2">
                <h3 className="text-center relative flex justify-center text-xl sm:text-sm text-gray-900 font-medium leading-8">
                  {user?.user_metadata?.name}
                  {user?.rtsvrf_fan_verification_status && (
                    <span className="absolute right-7 sm:right-0">
                      <img
                        className="ml-1"
                        width={"15"}
                        height={"15"}
                        alt="verified"
                        src={verified}
                      />
                    </span>
                  )}
                </h3>
                <div className="text-center text-gray-400 text-xs font-semibold">
                  <p> {user?.email}</p>
                </div>
                <table className="text-xs my-3">
                  <tbody>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">
                        Country
                      </td>
                      <td className="px-2 py-2">
                        {country || user?.user_metadata?.country || "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-2 py-2 text-gray-500 font-semibold">
                        User ID
                      </td>
                      <td className="px-2 py-2">{user?.id}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Profile;
