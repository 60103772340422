import React from "react";
import UserIcon from "../rtsvrf-assets/icons/user.png";
import verified from "../rtsvrf-assets/icons/av-tick.png";
import EditArtistProfileImage from "./EditArtistProfileImage";

function ArtistProfile({
  artist,
  artistImage,
  userIpData,
  user,
  allowEdit,
  reloadData,
}) {
  const downloadQR = () => {
    const canvas = document.getElementById("qr-code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${artist?.artist_name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="my-10 flex flex-col">
      {artist ? (
        <div className={`flex flex-col md:justify-center md:items-center`}>
          <h3 className="max-w-sm w-72 sm:w-48 text-center text-lg my-2 font-bold text-white">
            Artist Profile
          </h3>

          <div className="max-w-sm w-72 sm:w-48">
            <div className="bg-white shadow-xl rounded-lg p-6">
              {artist?.rtsvrf_profile_claim_status && (
                <span className="w-full flex items-end justify-end -mt-4 ml-4">
                  <img
                    className="ml-1"
                    width={"15"}
                    height={"15"}
                    alt="verified"
                    src={verified}
                  />
                </span>
              )}
              <div className="photo-wrapper p-2">
                {artistImage ? (
                  <img
                    className="w-44 h-44 xl:w-36 xl:h-36 sm:w-32 sm:h-32 rounded-full mx-auto"
                    src={artistImage}
                    alt="artist"
                  />
                ) : (
                  <img
                    className="w-44 h-44 xl:w-36 xl:h-36 rounded-full mx-auto"
                    src={UserIcon}
                    alt="profile-img"
                  />
                )}
                {allowEdit && (
                  <EditArtistProfileImage
                    artist={artist}
                    user={user}
                    userIpData={userIpData}
                    reloadData={reloadData}
                  />
                )}
              </div>
              <div className="p-2">
                <h3 className="text-center relative flex justify-center text-xl sm:text-sm text-gray-900 font-medium leading-8">
                  {artist?.artist_name}
                </h3>
                <div className="text-center text-gray-400 text-xs font-semibold">
                  <p>
                    {artist?.artist_bio_text?.slice(0, 120)}
                    {artist?.artist_bio_text?.length > 120 ? "..." : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {artist &&
        artist.rtsvrf_avezqrc_code_id &&
        artist.bucket_qrcode_image_path && (
          <div className="w-full flex flex-col justify-center items-center my-4">
            <img
              alt="qr-code"
              width={"150"}
              height={"150"}
              id="qr-code"
              src={artist.bucket_qrcode_image_path}
            />
            <p
              className="cursor-pointer text-blue-800 underline text-sm"
              onClick={downloadQR}
            >
              Download QR{" "}
            </p>
          </div>
        )}
    </div>
  );
}

export default ArtistProfile;
