import React from "react";
import EditIcon from "../rtsvrf-assets/icons/pencil.png";
import ViewIcon from "../rtsvrf-assets/icons/eye.png";
import defaultIcon from "../rtsvrf-assets/icons/user.png";
import fanIcon from "../rtsvrf-assets/icons/analytics.png";
import labelIcon from "../rtsvrf-assets/icons/handshake.png";
import newsIcon from "../rtsvrf-assets/icons/newspaper.png";
import ProfileIcon from "../rtsvrf-assets/icons/profile.png";
import { useNavigate } from "react-router-dom";

function DelegatedArtists({
  loading,
  editorProfile,
  delegatedArtists,
  labelManagedArtists,
  artistImages,
}) {
  const navigate = useNavigate();

  const handleArtistEdit = (artistId) => {
    if (!artistId) return;
    navigate(`edit/${artistId}`);
  };
  const handleArtistProfile = (artistId) => {
    if (!artistId) return;
    window.open(
      `${process.env.REACT_APP_PROFILE_URL}/artist/${artistId}`,
      "_blank",
      "rel=noopener noreferrer"
    );
  };
  const handleArtistProfileV2 = (artistId) => {
    if (!artistId) return;
    navigate(`edit/${artistId}?tab=v2`);
  };

  const handleArtistFanData = (artistId, params) => {
    if (!artistId) return;
    navigate(`fandata/${artistId}${params ? `?${params}` : ""}`);
  };
  console.log(delegatedArtists, labelManagedArtists, "artistsss");
  return (
    <>
      {!loading ? (
        <div className="flex flex-col">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            My Artist Delegations
          </h3>

          <div className="flex flex-col justify-center items-center bg-gray-200 shadow-xl rounded-lg p-4 sm:p-2">
            <div className="text-left md:my-3 sm:w-72 w-full bg-white shadow-xl rounded-lg p-2 my-2 max-h-96 overflow-y-auto">
              {delegatedArtists?.map((artist) => {
                const artistImageData = artistImages?.find(
                  (img) => img.rtsvrf_artist_id === artist?.rtsvrf_artist_id
                );

                const artistImage = artistImageData
                  ? artistImageData[artistImageData.artist_picture_preference]
                  : null;
                return (
                  <div class="flex items-center md:items-start">
                    <img
                      src={artistImage || defaultIcon}
                      alt="fav"
                      width={"45"}
                      height={"45"}
                      style={defaultIcon && { padding: "5px" }}
                    />
                    <div class="text-md flex items-center w-full">
                      <h4 class="mt-0 text-xs ml-2 mr-2 font-bold flex items-center leading-tight text-gray-500">
                        {artist?.artist_name}
                      </h4>
                      <span className="ml-auto flex items-center">
                        <span className="ml-auto cursor-pointer">
                          <img
                            onClick={() =>
                              handleArtistProfile(artist?.rtsvrf_artist_id)
                            }
                            src={ViewIcon}
                            alt="edit"
                            width="20"
                            height="20"
                          />
                        </span>
                        <span className="ml-2 cursor-pointer">
                          <img
                            onClick={() =>
                              handleArtistProfileV2(artist?.rtsvrf_artist_id)
                            }
                            src={ProfileIcon}
                            alt="edit"
                            width="18"
                            height="18"
                          />
                        </span>

                        <span
                          className={`cursor-pointer mx-2 ${
                            editorProfile?.editor_delegated_artists?.includes(
                              artist?.rtsvrf_artist_id
                            )
                              ? ""
                              : "invisible"
                          }`}
                        >
                          <img
                            onClick={() =>
                              handleArtistEdit(artist?.rtsvrf_artist_id)
                            }
                            src={EditIcon}
                            alt="edit"
                            width="15"
                            height="15"
                          />
                        </span>

                        <span
                          className={`cursor-pointer ${
                            editorProfile?.fandata_delegated_artists?.includes(
                              artist?.rtsvrf_artist_id
                            )
                              ? ""
                              : "invisible"
                          }`}
                        >
                          <img
                            onClick={() =>
                              handleArtistFanData(artist?.rtsvrf_artist_id)
                            }
                            src={fanIcon}
                            alt="edit"
                            width="15"
                            height="15"
                          />
                        </span>
                        <span
                          className={`cursor-pointer mx-2 ${
                            editorProfile?.fandata_delegated_artists?.includes(
                              artist?.rtsvrf_artist_id
                            )
                              ? ""
                              : "invisible"
                          }`}
                        >
                          <a
                            href={`${process.env.REACT_APP_NEWS_LINK}${artist?.rtsvrf_artist_id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={newsIcon}
                              alt="edit"
                              width="15"
                              height="15"
                            />
                          </a>
                        </span>
                        <span
                          className={`cursor-pointer ${
                            artist?.share_data_with_label &&
                            labelManagedArtists.find(
                              (e) =>
                                e.rtsvrf_artist_id === artist?.rtsvrf_artist_id
                            )
                              ? ""
                              : "invisible"
                          }`}
                        >
                          <img
                            onClick={() =>
                              handleArtistFanData(
                                artist?.rtsvrf_artist_id,
                                `label=true`
                              )
                            }
                            src={labelIcon}
                            alt="edit"
                            width="22"
                            height="22"
                          />
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
              {labelManagedArtists
                ?.filter(
                  (artist) =>
                    !delegatedArtists?.find(
                      (e) => e.rtsvrf_artist_id === artist?.rtsvrf_artist_id
                    )
                )
                .map((artist) => {
                  const artistImageData = artistImages?.find(
                    (img) => img.rtsvrf_artist_id === artist?.rtsvrf_artist_id
                  );

                  const artistImage = artistImageData
                    ? artistImageData[artistImageData.artist_picture_preference]
                    : null;
                  return (
                    <div class="flex items-center md:items-start">
                      <img
                        src={artistImage || defaultIcon}
                        alt="fav"
                        width={"45"}
                        height={"45"}
                        style={defaultIcon && { padding: "5px" }}
                      />
                      <div class="text-md flex items-center w-full">
                        <h4 class="mt-0 text-xs ml-2 mr-2 font-bold flex items-center leading-tight text-gray-500">
                          {artist?.artist_name}
                        </h4>
                        <span className="ml-auto flex items-center">
                          <span className="ml-auto cursor-pointer">
                            <img
                              onClick={() =>
                                handleArtistProfile(artist?.rtsvrf_artist_id)
                              }
                              src={ViewIcon}
                              alt="edit"
                              width="20"
                              height="20"
                            />
                          </span>
                          <span className="ml-2 cursor-pointer">
                            <img
                              onClick={() =>
                                handleArtistProfileV2(artist?.rtsvrf_artist_id)
                              }
                              src={ProfileIcon}
                              alt="edit"
                              width="18"
                              height="18"
                            />
                          </span>

                          <span
                            className={`cursor-pointer mx-2 ${
                              editorProfile?.editor_delegated_artists?.includes(
                                artist?.rtsvrf_artist_id
                              )
                                ? ""
                                : "invisible"
                            }`}
                          >
                            <img
                              onClick={() =>
                                handleArtistEdit(artist?.rtsvrf_artist_id)
                              }
                              src={EditIcon}
                              alt="edit"
                              width="15"
                              height="15"
                            />
                          </span>

                          <span
                            className={`cursor-pointer ${
                              editorProfile?.fandata_delegated_artists?.includes(
                                artist?.rtsvrf_artist_id
                              )
                                ? ""
                                : "invisible"
                            }`}
                          >
                            <img
                              onClick={() =>
                                handleArtistFanData(artist?.rtsvrf_artist_id)
                              }
                              src={fanIcon}
                              alt="edit"
                              width="15"
                              height="15"
                            />
                          </span>
                          <span
                            className={`cursor-pointer mx-2 ${
                              editorProfile?.fandata_delegated_artists?.includes(
                                artist?.rtsvrf_artist_id
                              )
                                ? ""
                                : "invisible"
                            }`}
                          >
                            <a
                              href={`${process.env.REACT_APP_NEWS_LINK}${artist?.rtsvrf_artist_id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={newsIcon}
                                alt="edit"
                                width="15"
                                height="15"
                              />
                            </a>
                          </span>
                          <span
                            className={`cursor-pointer ${
                              artist?.share_data_with_label &&
                              labelManagedArtists.find(
                                (e) =>
                                  e.rtsvrf_artist_id ===
                                  artist?.rtsvrf_artist_id
                              )
                                ? ""
                                : "invisible"
                            }`}
                          >
                            <img
                              onClick={() =>
                                handleArtistFanData(
                                  artist?.rtsvrf_artist_id,
                                  `label=true`
                                )
                              }
                              src={labelIcon}
                              alt="edit"
                              width="22"
                              height="22"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-around items-center bg-white shadow-xl rounded-lg w-80 h-80 p-2 mt-10">
          <div
            class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default DelegatedArtists;
