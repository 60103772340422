import React from "react";

const FanFilters = ({ setColumns, columns }) => {
  const handleColumns = (column) => {
    let columnList = [...columns];
    if (columnList.includes(column)) {
      columnList = columnList.filter((e) => e !== column);
      setColumns(columnList);
    } else {
      setColumns([...columnList, column]);
    }
  };
  console.log(columns, "columns");
  return (
    <div className="flex justify-center items-center w-full my-8">
      <div className="flex flex-col items-start w-full max-w-lg bg-white border border-gray-200 rounded-lg sm:flex">
        <ul class="flex flex-wrap items-start text-sm font-medium text-gray-900">
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <label
                for="City-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-bold text-gray-900 dark:text-gray-300"
              >
                Fan Demographics:
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="City-checkbox-list"
                type="checkbox"
                checked={columns?.includes("city")}
                onClick={() => handleColumns("city")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="City-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                City
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Country-checkbox-list"
                type="checkbox"
                checked={columns?.includes("temp_country")}
                onChange={() => handleColumns("temp_country")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Country-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Country
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Birth-checkbox-list"
                type="checkbox"
                checked={columns?.includes("birth_year")}
                onChange={() => handleColumns("birth_year")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Birth-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Birth Year
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Gender-checkbox-list"
                type="checkbox"
                checked={columns?.includes("gender")}
                onChange={() => handleColumns("gender")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Gender-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Gender
              </label>
            </div>
          </li>
        </ul>
        <ul class="flex flex-wrap items-start text-sm font-medium text-gray-900">
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <label
                for="City-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-bold text-gray-900 dark:text-gray-300"
              >
                Fan Communications:
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Facebook-checkbox-list"
                type="checkbox"
                checked={columns?.includes("facebook_ID")}
                onChange={() => handleColumns("facebook_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Facebook-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Facebook
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Twitter-checkbox-list"
                type="checkbox"
                checked={columns?.includes("twitter_ID")}
                onChange={() => handleColumns("twitter_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Twitter-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                X (Twitter)
              </label>
            </div>
          </li>

          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Instagram-checkbox-list"
                type="checkbox"
                checked={columns?.includes("instagram_ID")}
                onChange={() => handleColumns("instagram_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Instagram-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Instagram
              </label>
            </div>
          </li>
        </ul>

        <ul class="flex flex-wrap items-start text-sm font-medium text-gray-900">
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="twitch-checkbox-list"
                type="checkbox"
                checked={columns?.includes("twitch_ID")}
                onChange={() => handleColumns("twitch_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="twitch-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Twitch
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="threads-checkbox-list"
                type="checkbox"
                checked={columns?.includes("threads_ID")}
                onChange={() => handleColumns("threads_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="threads-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Threads
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="snapchat-checkbox-list"
                type="checkbox"
                checked={columns?.includes("snapchat_ID")}
                onChange={() => handleColumns("snapchat_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="snapchat-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Snapchat
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="discord-checkbox-list"
                type="checkbox"
                checked={columns?.includes("discord_ID")}
                onChange={() => handleColumns("discord_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="discord-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Discord
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="mastodon-checkbox-list"
                type="checkbox"
                checked={columns?.includes("mastodon_ID")}
                onChange={() => handleColumns("mastodon_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="mastodon-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Mastodon
              </label>
            </div>
          </li>
        </ul>

        <ul class="flex flex-wrap items-start text-sm font-medium text-gray-900">
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="bluesky-checkbox-list"
                type="checkbox"
                checked={columns?.includes("bluesky_ID")}
                onChange={() => handleColumns("bluesky_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="bluesky-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Bluesky
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="signal-checkbox-list"
                type="checkbox"
                checked={columns?.includes("signal_ID")}
                onChange={() => handleColumns("signal_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="signal-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Signal
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="whatsapp-checkbox-list"
                type="checkbox"
                checked={columns?.includes("whatsapp_ID")}
                onChange={() => handleColumns("whatsapp_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="whatsapp-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Whatsapp
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="telegram-checkbox-list"
                type="checkbox"
                checked={columns?.includes("telegram_ID")}
                onChange={() => handleColumns("telegram_ID")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="telegram-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Telegram
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0   dark:border-gray-600">
            <div class="flex items-center pl-3">
              <label
                for="City-checkbox-list"
                class="w-full py-3 ml-2 invisible text-sm font-bold text-gray-900 dark:text-gray-300"
              >
                hidden:
              </label>
            </div>
          </li>
        </ul>

        <ul class="flex flex-wrap items-start text-sm font-medium text-gray-900">
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <label
                for="City-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-bold text-gray-900 dark:text-gray-300"
              >
                Fan Messaging Preferences:
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Favourites-checkbox-list"
                type="checkbox"
                checked={columns?.includes("email")}
                onChange={() => handleColumns("email")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Favourites-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Email
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Favourites-checkbox-list"
                type="checkbox"
                checked={columns?.includes("sms")}
                onChange={() => handleColumns("sms")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Favourites-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                SMS
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Favourites-checkbox-list"
                type="checkbox"
                checked={columns?.includes("push")}
                onChange={() => handleColumns("push")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Favourites-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                In-app
              </label>
            </div>
          </li>
        </ul>
        <ul class="flex flex-wrap items-start text-sm font-medium text-gray-900">
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <label
                for="City-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-bold text-gray-900 dark:text-gray-300"
              >
                Fan Favourites:
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Top-checkbox-list"
                type="checkbox"
                checked={columns?.includes("favourite_1")}
                onChange={() => handleColumns("favourite_1")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Top-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Top Artist{" "}
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Favourites-checkbox-list"
                type="checkbox"
                checked={columns?.includes("top5Artists")}
                onChange={() => handleColumns("top5Artists")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Favourites-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Top 5 Favourites
              </label>
            </div>
          </li>
        </ul>
        <ul class="flex flex-wrap items-start text-sm font-medium text-gray-900">
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <label
                for="City-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-bold text-gray-900 dark:text-gray-300"
              >
                Other
              </label>
            </div>
          </li>
          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Status-checkbox-list"
                type="checkbox"
                checked={columns?.includes("fan_status_level")}
                onChange={() => handleColumns("fan_status_level")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Status-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Status Level
              </label>
            </div>
          </li>

          <li class="border-b border-gray-200 sm:border-b-0  dark:border-gray-600">
            <div class="flex items-center pl-3">
              <input
                id="Wallet-checkbox-list"
                type="checkbox"
                checked={columns?.includes("wallet_address")}
                onChange={() => handleColumns("wallet_address")}
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="Wallet-checkbox-list"
                class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Has Wallet
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FanFilters;
