import React, { useState } from "react";
import ReactGA from "react-ga4";
import { updateArtistSettings } from "../rtsvrf-actions/Actions";
import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";

function ArtistNewsSettings({
  user,
  userIpData,
  artist,
  artistSettings,
  reloadData,
}) {
  const [loading, setLoading] = useState();
  const [settings, setSettings] = useState(artistSettings);

  const handleForm = (name, value) => {
    setSettings({ ...settings, [name]: value });
    console.log(name, value, settings);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Save Artist Settings",
      action: "click",
      label: "Save Artist Settings",
    });

    let settingsDifference = Object.keys(settings).filter(
      (k) => settings[k] !== artistSettings[k]
    );

    const settingsDetails =
      settingsDifference?.map(
        (field) =>
          `${field} changed from ${artistSettings[field] || null} to ${
            settings[field] || null
          }`
      ) || [];

    const settingsResult = await updateArtistSettings({
      ...settings,
    });

    setLoading(false);
    if (settingsResult?.error) {
      toast.error("Failed to update settings! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess(`${artist?.artist_name || "Artist"} Settings Updated`, {
        ...userIpData,
        email: user.email,
        timestamp: new Date(),
        details: `${artist?.artist_name || "Artist"}:${
          artist?.rtsvrf_artist_id
        } settings:  ${[...settingsDetails].join(",")}`,
        type: artist?.rtsvrf_artist_id,
      });
      reloadData();
    }
  };

  return (
    <>
      {settings && artist ? (
        <div className="my-10 w-full ml-5 lg:ml-0">
          <h3 className="text-center text-lg my-2 font-bold text-white">
            Focus Artist - {artist?.artist_name} - {artist?.rtsvrf_artist_id}
          </h3>
          <div className="w-full my-2 flex flex-col justify-around bg-gray-200 shadow-xl rounded-lg p-4">
            <h3 className="text-gray-800 text-xs font-bold mb-2">
              Configure My Artist News Feed:
            </h3>

            <form className="w-full my-2 flex items-baseline md:flex-col justify-around">
              <div className="pr-4 w-full">
                <div className="w-full mb-6 flex items-center">
                  <input
                    id="web_search"
                    type="checkbox"
                    checked={settings?.web_search}
                    onClick={() =>
                      handleForm("web_search", !settings?.web_search)
                    }
                    class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="web_search"
                    className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                  >
                    Web Search
                  </label>
                </div>
              </div>
              <div className="pr-4 w-full">
                <div className="w-full mb-6 flex items-center">
                  <input
                    id="twitter"
                    type="checkbox"
                    checked={settings?.twitter}
                    onClick={() => handleForm("twitter", !settings?.twitter)}
                    class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="twitter"
                    className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                  >
                    X (Twitter)
                  </label>
                </div>
                <div className="w-full mb-6 flex items-center">
                  <input
                    id="facebook"
                    type="checkbox"
                    defaultChecked={settings?.facebook}
                    onClick={() => handleForm("facebook", !settings?.facebook)}
                    class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="facebook"
                    className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                  >
                    Facebook
                  </label>
                </div>

                <div className="w-full mb-6 flex items-center">
                  <input
                    id="instagram"
                    type="checkbox"
                    defaultChecked={settings?.instagram}
                    onClick={() =>
                      handleForm("instagram", !settings?.instagram)
                    }
                    class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="instagram"
                    className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                  >
                    Instagram
                  </label>
                </div>
              </div>
              <div className="pr-4 w-full">
                <div className="w-full mb-6 flex items-center">
                  <input
                    id="related_artists"
                    type="checkbox"
                    defaultChecked={settings?.related_artists}
                    onClick={() =>
                      handleForm("related_artists", !settings?.related_artists)
                    }
                    class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="related_artists"
                    className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                  >
                    Related Artists
                  </label>
                </div>
                <div className="w-full mb-6 flex items-center">
                  <input
                    id="industry_news"
                    type="checkbox"
                    defaultChecked={settings?.industry_news}
                    onChange={() =>
                      handleForm("industry_news", !settings?.industry_news)
                    }
                    class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="industry_news"
                    className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                  >
                    Industry News
                  </label>
                </div>
                <div className="w-full mb-6 flex items-center">
                  <input
                    id="genre_news"
                    type="checkbox"
                    defaultChecked={settings?.genre_news}
                    onClick={() =>
                      handleForm("genre_news", !settings?.genre_news)
                    }
                    class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="genre_news"
                    className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                  >
                    Genre News
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              className="w-40 my-2 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ArtistNewsSettings;
