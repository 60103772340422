import React from "react";

const NewsCustomization = () => {
  return (
    <div class="p-2 justify-between items-center flex flex-col overflow-y-scroll">
      <p className="text-white">Coming Soon</p>
    </div>
  );
};

export default NewsCustomization;
