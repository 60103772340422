export const DataSharingPreference = {
  SHARE_ALL_DATA_WITH_FOLLOWED: "SHARE_ALL_DATA_WITH_FOLLOWED",
  COMMS_ONLY_WITH_FOLLOWED: "COMMS_ONLY_WITH_FOLLOWED",
  DEMOG_ONLY_WITH_FOLLOWED: "DEMOG_ONLY_WITH_FOLLOWED",
  SHARE_NO_DATA_WITH_FOLLOWED: "SHARE_NO_DATA_WITH_FOLLOWED",
};

export const DemographicColumns = [
  "city",
  "temp_country",
  "gender",
  "birth_year",
];

export const CommunicationColumns = [
  "twitter_ID",
  "instagram_ID",
  "facebook_ID",
  "phone_number",
  "fan_email",
  "fan_name",
  "threads_ID",
  "snapchat_ID",
  "discord_ID",
  "mastodon_ID",
  "bluesky_ID",
  "signal_ID",
  "whatsapp_ID",
  "telegram_ID",
  "twitch_ID",
];

export const DemoTopColumns = ["demo_top100", "demo_top300", "demo_top999"];

export const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.85)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
    padding: "12px",
    maxWidth: "400px",
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
};

export const fieldMappings = [
  { name: "City", column: "city", type: "order" },
  { name: "Country", column: "temp_country", type: "order" },
  { name: "Fan Status", column: "fan_status_level", type: "order" },
  { name: "Wallet", column: "wallet_address", type: "not" },
  { name: "X (Twitter)", column: "twitter_ID", type: "not" },
  { name: "Twitch", column: "twitch_ID", type: "not" },
  { name: "Gender", column: "gender", type: "order" },
  { name: "Birth Year", column: "birth_year", type: "order" },
  { name: "Facebook", column: "facebook_ID", type: "not" },
  { name: "Instagram", column: "instagram_ID", type: "not" },
  { name: "Top 5 Favourites", column: "top5Artists", type: "filter" },
  { name: "Top Artist", column: "favourite_1", type: "filter" },
  { name: "Email", column: "email", type: "filter" },
  { name: "SMS", column: "sms", type: "filter" },
  { name: "In-app", column: "push", type: "filter" },
  { name: "Threads", column: "threads_ID", type: "not" },
  { name: "Snapchat", column: "snapchat_ID", type: "not" },
  { name: "Discord", column: "discord_ID", type: "not" },
  { name: "Mastodon", column: "mastodon_ID", type: "not" },
  { name: "Bluesky", column: "bluesky_ID", type: "not" },
  { name: "Signal", column: "signal_ID", type: "not" },
  { name: "Whatsapp", column: "whatsapp_ID", type: "not" },
  { name: "Telegram", column: "telegram_ID", type: "not" },
];
