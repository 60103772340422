import React, { useState } from "react";
import { sendMessagetoFans } from "../rtsvrf-actions/Actions";
import "./Chat.css";
import "../../node_modules/react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { Tab, Tabs } from "./Tabs";
import EmailNotification from "./EmailNotification";
import InboxNotification from "./InboxNotification";
import NewsCustomization from "./NewsCustomization";
import { logSuccess } from "../rtsvrf-actions/Logging";

const Chatbox = ({
  delegatedArtists,
  userIpData,
  artistEditorProfile,
  artistEditor,
  selectedArtist,
  selectedArtistName,
  userIds,
  customized,
}) => {
  const [list, setList] = useState("");
  const [currentTab, setCurrentTab] = useState("");

  const [loading, setLoading] = useState(false);
  const [artistId, setArtistId] = useState(selectedArtist || "");
  const [artistName, setArtistName] = useState(selectedArtistName || "");

  const createMessage = async (data) => {
    if (loading) return;
    if (data?.body?.split(" ").length > 200) {
      toast.error("Message length should not exceed 200 words.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setLoading(true);

    let users = [];
    if (userIds?.length) {
      users = userIds?.map((user) => user.rtsvrf_fan_uuid);
    }
    const listName = customized ? `_slice_dice_${new Date().getTime()}` : list;
    const listId = artistId + listName;

    try {
      await sendMessagetoFans(listId, {
        title: data.title,
        body: data.body,
        type: data.type,
        artistName,
        users: [...users, artistEditor.supabase_user_reference],
        list: {
          list_name: `${artistName}${listName}`,
          list_description: `${artistName}${listName}`,
        },
      });
      await logSuccess(`Sent message to ${artistName} fans`, {
        ...userIpData,
        email: artistEditorProfile.email,
        timestamp: new Date(),
        details: `${
          userIds?.length
            ? `Sent message to ${userIds.length} fans`
            : `Sent message to ${artistName}${listName} list fans`
        } via ${data.type}`,
        type: "Editor",
      });
      toast.success("Sent message to fans successfully.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error("Failed to send! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setLoading(false);
  };
  console.log(artistEditor, "artistEditor");
  return (
    <div
      className={`p-2 justify-between items-center flex flex-col ${
        customized && "overflow-y-scroll max-h-screen"
      }`}
    >
      {currentTab === 2 ? (
        <h3
          className={`w-full text-center text-lg mt-2 font-bold ${
            customized ? "text-black" : "text-white"
          }`}
        >
          Create a Fan News Feed Item
        </h3>
      ) : customized ? (
        <h3 className="w-full text-center text-lg mt-2 font-bold text-black">
          Send message to selected fans in the table
        </h3>
      ) : (
        <h3 className="w-full text-center text-lg mt-2 font-bold text-white">
          Send message to fans
        </h3>
      )}

      {!customized &&
        artistEditor?.fandata_delegated_artists?.length &&
        delegatedArtists?.length && (
          <div class="w-full mb-3">
            <label class="block  tracking-wide text-white text-xs font-bold mb-1">
              Artists
            </label>
            <div class="relative">
              <select
                onChange={(e) => {
                  setArtistId(e.target.value);
                  setArtistName(e.target.selectedOptions[0].text);
                }}
                defaultValue={artistId}
                class="block appearance-none text-xs w-full bg-white border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled value={""}>
                  Select a artist
                </option>
                {delegatedArtists?.map((artist) => {
                  if (
                    artistEditor?.fandata_delegated_artists?.includes(
                      artist.rtsvrf_artist_id
                    )
                  ) {
                    return (
                      <option
                        key={artist.artist_name}
                        value={artist.rtsvrf_artist_id}
                      >
                        {artist.artist_name}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
              </select>
            </div>
          </div>
        )}
      {!customized &&
        artistId &&
        artistEditor?.fandata_delegated_artists?.length && (
          <div class="w-full mb-3">
            <label class="block  tracking-wide text-white text-xs font-bold mb-1">
              List
            </label>
            <div class="relative">
              <select
                onChange={(e) => setList(e.target.value)}
                defaultValue={list}
                class="block appearance-none text-xs w-full bg-white border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled value={""}>
                  Select a list
                </option>
                <option value={"_all_followers"}>All followers</option>
                <option value={"_number1"}>Number 1 favourite</option>
                <option value={"_top5"}>Top 5 favourites</option>
              </select>
            </div>
          </div>
        )}
      <div class="my-3">
        <Tabs>
          <Tab
            handleTab={(e) => setCurrentTab(e)}
            component={
              <>
                {
                  <InboxNotification
                    loading={loading}
                    createMessage={createMessage}
                    artistName={artistName}
                    artistId={artistId}
                    currentTab={currentTab}
                  />
                }
              </>
            }
          >
            Inbox/SMS
          </Tab>
          <Tab
            handleTab={(e) => setCurrentTab(e)}
            component={
              <>
                {
                  <EmailNotification
                    createMessage={createMessage}
                    loading={loading}
                    artistId={artistId}
                    currentTab={currentTab}
                  />
                }
              </>
            }
          >
            Email
          </Tab>

          <Tab
            handleTab={(e) => setCurrentTab(e)}
            component={
              <div>
                <NewsCustomization />
              </div>
            }
          >
            News
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Chatbox;
