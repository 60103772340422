import React, { useState, useEffect } from "react";

import {
  getArtistEditorMappingsById,
  getArtistImage,
  getArtistsByLabel,
  getArtistsByRtsvrfId,
  getLogHistory,
  getTotalLogHistory,
  getUserAuth,
} from "../rtsvrf-actions/Actions";

import Loading from "../rtsvrf-components/Loading";
import Profile from "../rtsvrf-components/Profile";
import Navbar from "../rtsvrf-components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import EditProfile from "../rtsvrf-components/EditProfile";
import DelegatedArtists from "../rtsvrf-components/DelegatedArtists";
import Table from "../rtsvrf-components/Table";
import Pagination from "../rtsvrf-components/Pagination";
import { Tab, Tabs } from "../rtsvrf-components/Tabs";
import Chatbox from "../rtsvrf-components/ChatBox";

function Home({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [editorProfile, setEditorProfile] = useState();
  const [delegatedArtists, setDelegatedArtists] = useState(null);
  const [labelManagedArtists, setLabelManagedArtists] = useState([]);
  const [artistImages, setArtistImages] = useState([]);
  const [logs, setLogs] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();
  const listPerPage = 5;

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const reloadData = async () => {
    if (loading) return;
    console.log("hi");
    setLoading(true);
    const userAuthData = await getUserAuth();
    console.log(userAuthData);
    setUser(userAuthData);

    const result = await getArtistEditorMappingsById(id);
    const userData = result?.data;
    setEditorProfile(userData);
    if (userData) {
      if (
        userData?.editor_delegated_artists?.length ||
        userData?.fandata_delegated_artists?.length ||
        (userData?.is_label_group_manager && userData?.label_group_code)
      ) {
        let labelArtists = [];
        if (userData?.is_label_group_manager && userData?.label_group_code) {
          labelArtists = await getArtistsByLabel(userData.label_group_code);
          setLabelManagedArtists(labelArtists);
        }
        const artists = await getArtistsByRtsvrfId([
          ...(userData.editor_delegated_artists || []),
          ...(userData.fandata_delegated_artists || []),
        ]);
        const rtsvrfArtistIds =
          artists
            .concat(labelArtists)
            ?.map((artist) => artist?.rtsvrf_artist_id) || [];
        setDelegatedArtists(artists);
        const images = await getArtistImage(rtsvrfArtistIds);
        console.log(images);
        setArtistImages(images);
      } else {
        setDelegatedArtists([]);
        setArtistImages([]);
      }
    } else {
      navigate(`/404`);
    }
    if (userAuthData) {
      const logsHistory = await getLogHistory(userAuthData.email, "Editor");
      if (logsHistory?.data) setLogs(logsHistory.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function fetchPaginationData() {
      if (!user) return;
      const numberOfRecords = await getTotalLogHistory(user.email, "Editor");
      console.log(numberOfRecords);
      setTotal(numberOfRecords);
      const logsHistory = await getLogHistory(
        user.email,
        "Editor",
        currentPage,
        listPerPage
      );
      if (logsHistory?.data) setLogs(logsHistory.data);
    }
    fetchPaginationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
    >
      {user && (
        <Navbar
          user={user}
          userIpData={userIpData}
          links={[
            { label: "Home", link: `/artadm/${user.id}` },
            { label: "Artist Admin Profile", link: null },
          ]}
        />
      )}

      <Tabs>
        <Tab
          component={
            <div className="flex md:flex-col md:flex-nowrap flex-wrap min-2xl:justify-center justify-around md:items-center">
              {user && (
                <Profile
                  reloadData={reloadData}
                  user={user}
                  userIpData={userIpData}
                />
              )}
              <div className="flex flex-col min-2xl:ml-28 my-10 items-center">
                {user ? (
                  <EditProfile
                    reloadData={reloadData}
                    user={user}
                    userIpData={userIpData}
                  />
                ) : null}
              </div>
            </div>
          }
          active
        >
          My Details
        </Tab>
        <Tab
          component={
            <div className="flex md:flex-col md:flex-nowrap min-2xl:justify-center flex-wrap justify-around md:items-center">
              {user && (
                <Profile
                  reloadData={reloadData}
                  user={user}
                  userIpData={userIpData}
                />
              )}
              <div className="flex flex-col my-10 min-2xl:ml-28 items-center">
                {(delegatedArtists || labelManagedArtists) && (
                  <DelegatedArtists
                    loading={loading}
                    editorProfile={editorProfile}
                    delegatedArtists={delegatedArtists}
                    labelManagedArtists={labelManagedArtists}
                    artistImages={artistImages}
                  />
                )}
              </div>
            </div>
          }
        >
          Artist Delegations
        </Tab>

        <Tab
          hide={!editorProfile?.fandata_delegated_artists?.length}
          component={
            <div className="flex md:flex-col md:flex-nowrap min-2xl:justify-center flex-wrap justify-around md:items-center">
              {user && (
                <Profile
                  reloadData={reloadData}
                  user={user}
                  userIpData={userIpData}
                />
              )}
              <div className="flex flex-col my-10 min-2xl:ml-28 items-center">
                <Chatbox
                  userIpData={userIpData}
                  artistEditorProfile={user}
                  artistEditor={editorProfile}
                  delegatedArtists={delegatedArtists}
                />
              </div>
            </div>
          }
        >
          Messaging
        </Tab>

        <Tab
          component={
            <div className="flex md:flex-col md:flex-nowrap min-2xl:justify-center flex-wrap justify-around md:items-center">
              {user && (
                <Profile
                  reloadData={reloadData}
                  user={user}
                  userIpData={userIpData}
                />
              )}
              <div className="flex flex-col my-10 min-2xl:ml-28 items-center">
                <Table feed={logs} />
                {logs?.length ? (
                  <div className="py-2 flex items-center justify-center">
                    <Pagination
                      listPerPage={listPerPage}
                      total={total}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          }
        >
          Activity
        </Tab>
      </Tabs>
    </div>
  );
}

export default Home;
