import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import CustomToolbar from "./CustomToolbar";
import "./Chat.css";
import "../../node_modules/react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { decode } from "base64-arraybuffer";
import { uploadImageToBucket } from "../rtsvrf-actions/Actions";

const EmailNotification = ({
  loading,
  createMessage,
  artistId,
  currentTab,
}) => {
  const [title, setTitle] = useState("");
  const quillRef = useRef();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue("");
    setTitle("");
  }, [artistId, currentTab]);

  const handleImageUpload = async () => {
    const quill = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.addEventListener("change", async () => {
      const file = input.files[0];

      if (!file) return;

      // Create an Image object to check the image dimensions
      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        console.log(image.width, image.height);
        if (image.width <= 450 && image.height <= 800) {
          // Continue with the image upload
          const reader = new FileReader();

          reader.onload = async (event) => {
            const base64Image = event.target.result.split(",")[1];
            const arrayBuffer = decode(base64Image);

            const result = await uploadImageToBucket(
              "bkt_staged_pub_msgimgupl",
              file,
              arrayBuffer
            );

            if (result.error) {
              toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              // Insert the uploaded image into the editor
              quill.focus();
              const range = quill.getSelection();
              quill.insertEmbed(range.index, "image", result);
            }
          };

          reader.readAsDataURL(file);
        } else {
          // Display an error message for invalid image dimensions
          toast.error(
            "Image dimensions should be less than or equal to 800x450 pixels.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      };
    });
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };
  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
  ];

  const handleSendMessage = async () => {
    if (!title || !value) {
      toast.error("Add title and message properly!");
      return;
    }
    console.log(value);
    await createMessage({
      title: title,
      body: value,
      type: "email",
    });
  };
  return (
    <>
      <div class="w-full mb-3">
        <label class="block  tracking-wide text-white text-xs font-bold mb-1">
          Title
        </label>
        <input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          class="appearance-none text-xs block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          type="text"
          placeholder="Enter title for message..."
        />
      </div>
      <span className="text-xs font-bold text-gray-800">
        &nbsp;(Should not exceed 200 words)
      </span>
      <div className="bg-white max-w-2xl">
        <CustomToolbar onImageUpload={handleImageUpload} />

        <ReactQuill
          theme="snow"
          id="quill"
          ref={quillRef}
          placeholder="Enter body text for message..."
          value={value}
          onChange={setValue}
          modules={modules}
          formats={formats}
        />
      </div>
      <div className="w-full text-center">
        <button
          className="bg-white w-40 mt-2 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
          onClick={() => handleSendMessage()}
        >
          {loading ? "Sending..." : "Send"}
        </button>
      </div>
    </>
  );
};

export default EmailNotification;
